import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const BlogsAdd = () => {
  const [editorState, setEditorState] = useState<any>(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [image, setImage] = useState<File | null>(null);

  const fetchCategories = async () => {
    var authorId = sessionStorage.getItem("authorId");
    if (!authorId) {
      authorId = "2";
  }
 
    try {
      const response = await fetch(`https://backend.inkox.com/api/blogs-catg-auth/${authorId}`);
      const data = await response.json();
      const formattedCategories = data.map((category: any) => ({
        id: category.id,
        value: category.id.toString(),
        label: category.catg_name,
      }));
      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const schemaResolver = yupResolver(
    yup.object().shape({
      title: yup.string().required("Title is required"),
      add_description: yup.string().nullable(),
      date: yup.date().nullable(),
      description: yup.string().nullable(),
      catg_id: yup.string().nullable(),
      image: yup.string().nullable(),
    })
  );

  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onEditorStateChange = (editorStates: any) => {
    setEditorState(editorStates);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const formatDate = (date: any) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0]; // Ensure YYYY-MM-DD format
};

const onSubmit = async (data: any) => {
    var authorId = sessionStorage.getItem("authorId") || "";
    const formattedDate = formatDate(data.date);
   
    if (!authorId) {
      authorId = "2";
  }

    const formData = new FormData();
    formData.append("title", data.title);
    
    // Ensure description is not empty
    const description = editorState 
        ? draftToHtml(convertToRaw(editorState.getCurrentContent())) 
        : "";
    formData.append("description", description);
    
    formData.append("add_description", data.add_description || "");
    formData.append("date", formattedDate);
    formData.append("author_id", authorId); // Ensure author_id is set
    formData.append("catg_id", selectedCategory ? selectedCategory.value : "");

    if (image) {
        formData.append("image", image);
    }

    // Debugging: Log formData keys to see if everything is appended
    for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]); // Debugging: Log the key-value pairs
    }

    try {
        const response = await fetch("https://backend.inkox.com/api/blogs", {
            method: "POST",
            body: formData,
            headers: {
                "Accept": "application/json",
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error response from server:", errorData);
            alert(`Failed to create post: ${errorData.message || "Unknown error"}`);
            return;
        }

        const responseData = await response.json();
        console.log("Post created:", responseData);
        alert("Blog post created successfully!");

    } catch (error) {
        console.error("Error creating post:", error);
        alert(`Failed to create post. Error: ${error}`);
    }
};


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/blogs" },
          {
            label: "Add Blog",
            path: "/apps/ecommerce/blogs",
            active: true,
          },
        ]}
        title={"Add Blog"}
      />

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>
                <FormInput
                  name="title"
                  label="Title"
                  placeholder="Enter blog title"
                  containerClass={"mb-3"}
                  register={register}
                  key="title"
                  errors={errors}
                  control={control}
                />

                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName border border-1"
                    editorClassName="editorClassName px-2"
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{ minHeight: "150px" }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Categories</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={categories}
                    id="catg_id"
                    value={selectedCategory}
                    onChange={(selectedOption) => setSelectedCategory(selectedOption)}
                  />
                </Form.Group>

                <FormInput
                  type="textarea"
                  rows="3"
                  name="add_description"
                  label="Additional Description"
                  placeholder="Enter additional description"
                  containerClass={"mb-3"}
                  register={register}
                  key="add_description"
                  errors={errors}
                  control={control}
                />

                <FormInput
                  type="date"
                  name="date"
                  label="Date"
                  containerClass={"mb-3"}
                  register={register}
                  key="date"
                  errors={errors}
                  control={control}
                />

                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">Blog Image</h5>
                <Form.Group>
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button type="submit" className="btn w-sm btn-success waves-effect waves-light me-1">
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default BlogsAdd;
