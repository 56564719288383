import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import "./style.css";

interface Order {
  id: number;
  order_data: string[];
  designer_uploaded: string;
  designer_upload_main: string;
  created_at: string;
  updated_at: string;
  parsedOrderData?: any;
  orderId?: number;
  status?: string;
}

const Qc = () => {
  const [orderList, setOrderList] = useState<Order[]>([]);

  // Fetch the orders from API
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/designer-portal");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Parse order_data JSON strings and extract IDs
        const parsedOrders = data.map((order: any) => {
          const parsedOrderData = JSON.parse(order.order_data[0]);
          return {
            ...order,
            parsedOrderData,
            orderId: parsedOrderData.id, // Extract order ID for place-order API
            status: "Pending", // Default status until fetched
          };
        });

        // Fetch status from place-order API
        const fetchStatuses: Order[] = await Promise.all(
          parsedOrders.map(async (order: any): Promise<Order> => {
            try {
              const placeOrderResponse = await fetch(
                `https://backend.inkox.com/api/place-orders/${order.orderId}`
              );
              if (!placeOrderResponse.ok) {
                throw new Error("Failed to fetch place-order");
              }
              const placeOrderData = await placeOrderResponse.json();
              return { ...order, status: placeOrderData.Status || "Pending" };
            } catch (error) {
              console.error(`Error fetching status for order ${order.orderId}:`, error);
              return order; // Ensure order is returned even if status fetch fails
            }
          })
        );

        fetchStatuses.sort((a, b) => b.id - a.id);

        setOrderList(fetchStatuses);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);


  const ActionColumn = ({ row }: { row: any }) => {
    const navigate = useNavigate();
  
    const handleViewClick = () => {
      navigate("/apps/ecommerce/qc-detail", {
        state: { order: row.original },
      });
    };
  
    return (
      <Button  className="action-icon" onClick={handleViewClick}>
        <i className="mdi mdi-eye"></i>
      </Button>
    );
  };

  const EditColumn = ({ row }: { row: any }) => {
    const navigate = useNavigate();
  
    const handleViewClick = () => {
      navigate("/apps/ecommerce/edit-qc-detail", {
        state: { order: row.original },
      });
    };
  
    return (
      <Button  className="action-icon" onClick={handleViewClick}>
        <i className="mdi mdi-pencil"></i>
      </Button>
    );
  };
  // Define table columns
  const columns = [
    {
      Header: "Order ID",
      accessor: "id",
    },
    {
      Header: "Product Image",
      accessor: "productImage",
      Cell: ({ row }: { row: any }) => (
        <>
          {row.original.parsedOrderData.cartData &&
          row.original.parsedOrderData.cartData.length > 0 &&
          row.original.parsedOrderData.cartData[0].imagePreviews ? (
            row.original.parsedOrderData.cartData[0].imagePreviews.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={image.preview}
                  alt={`Product ${image.part}`}
                  style={{ width: "100px", height: "100px", marginRight: "5px" }}
                />
              )
            )
          ) : (
            "No Image Available"
          )}
        </>
      ),
    },
    {
      Header: "Order Type",
      accessor: "orderType",
      Cell: ({ row }: { row: any }) => (
        <>
          {row.original.parsedOrderData.cartData &&
          row.original.parsedOrderData.cartData.length > 0
            ? row.original.parsedOrderData.cartData[0].orderType
            : "Not Found"}
        </>
      ),
    },
    {
      Header: "Designer Uploaded File",
      accessor: "designerUploaded",
      Cell: ({ row }: { row: any }) => {
        let files = [];
        try {
          files = Array.isArray(row.original.designer_uploaded)
            ? row.original.designer_uploaded
            : JSON.parse(row.original.designer_uploaded);
        } catch (e) {
          console.error("Error parsing designer_uploaded:", e);
        }
    
        const handleDownload = async (file: string) => {
          try {
            const response = await fetch(`https://backend.inkox.com/storage/${file}`);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.split("/").pop() || "downloaded-file";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        };
    
        return (
          <>
            {files.length > 0
              ? files.map((file: string, index: number) => (
                  <button
                    key={index}
                    onClick={() => handleDownload(file)}
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      cursor: "pointer",
                      border: "none",
                      background: "none",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    title={`https://backend.inkox.com/storage/${file}`}
                  >
                    Download File {index + 1}
                  </button>
                ))
              : "No files available"}
          </>
        );
      },
    },
    {
      Header: "Designer Main File",
      accessor: "designerMainFile",
      Cell: ({ row }: { row: any }) => {
        let files = [];
        try {
          files = Array.isArray(row.original.designer_upload_main)
            ? row.original.designer_upload_main
            : JSON.parse(row.original.designer_upload_main);
        } catch (e) {
          console.error("Error parsing designer_upload_main:", e);
        }
    
        const handleDownload = async (file: string) => {
          try {
            const response = await fetch(`https://backend.inkox.com/storage/${file}`);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.split("/").pop() || "downloaded-file";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        };
    
        return (
          <>
            {files.length > 0
              ? files.map((file: string, index: number) => (
                  <button
                    key={index}
                    onClick={() => handleDownload(file)}
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      cursor: "pointer",
                      border: "none",
                      background: "none",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    title={`https://backend.inkox.com/storage/${file}`}
                  >
                    Download File {index + 1}
                  </button>
                ))
              : "No files available"}
          </>
        );
      },
    },
    {
      Header: "View",
      accessor: "view",
      Cell: ActionColumn,
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }: { row: any }) => (
        <>
          {row.original && row.original.status
            ? row.original.status
            : "Not Found"}
        </>
      ),
    },

    // {
    //   Header: "Edit",
    //   accessor: "edit",
    //   Cell: EditColumn,
    // },
  ];

  console.log("OrderList", orderList);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Qc", path: "/apps/ecommerce/orders", active: true },
        ]}
        title={"Qc"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={orderList}
                pageSize={10}
                isSortable={true}
                pagination={true}
                isSearchable={true}
                rowClassName={(row: { original: Order }) =>
                  row.original.status === "Approved" ? "highlighted-row" :  row.original.status === "Rejected" ? "rejected-row" : ""
                }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Qc;
