import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal, Form, Table } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
// import Table from "../../../components/Table";
import "./style.css";

// Interfaces
// interface QCData {
//   orderId: number;
//   work_order?: string;
//   poc?: string;
//   mock_ss?: string;
//   designer_final?: string;
// }

// interface Order {
//   id: number;
//   orderId: number;
//   orderType: string;
//   work_order?: string;
//   poc?: string;
//   mock_ss?: string;
//   designer_final?: string;
//   payment_status?: string;
//   orderStatus?: string;
//   created_at: string;
//   cartData?: any[];
//   track_no?: string;
// }

// interface Assignment {
//   id: number;
//   status: string;
//   order: Order;
// }

interface QCData {
  orderId: number;
  work_order: string;
  poc: string;
  mock_ss: string;
  designer_final: string;
}

interface ImagePreview {
  part: string;
  preview: string;
}

interface CartItem {
  id: number;
  productName: string;
  designName: string;
  productColor: string;
  totalQuantity: number;
  totalPrice: string;
  orderStatus: string;
  orderType: string;
  imagePreviews: ImagePreview[];
}

interface Order {
  id: number;
  nameoforganization: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  address: string;
  orderStatus: string;
  cartData: CartItem[];
  payment_status: string;
  payment_amount: string;
  track_no: string;
}

interface Vendor {
  id: number;
  fullname: string;
  location: string;
  contact: string;
  email: string;
}

interface Assignment {
  id: number;
  status: string;
  assigned_at: string;
  expires_at: string;
  remaining_time: string;
  order: Order;
  vendor: Vendor;
}


// Status update button for "Complete by Production"
const ActionColumn = ({ row, onStatusChange }: { row: any; onStatusChange: (orderId: number, status: string) => void }) => {
  const handleViewClick = async () => {
    try {
      const response = await fetch(
        `https://backend.inkox.com/api/orderStatus/${row.original.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json", Accept: "application/json" },
          body: JSON.stringify({ orderStatus: "Ready For Dispatched" }),
        }
      );

      if (!response.ok) throw new Error("Error while updating order status.");

      alert("This Order Is Ready For Dispatched");
      onStatusChange(row.original.id, "Ready For Dispatched");
    } catch (error) {
      console.error("Error:", error);
      alert("Error while updating order status.");
    }
  };

  if (row.original.orderStatus === "Ready For Dispatched") {
    return <span>Completed</span>;
  }

  return <Button className="btn btn-primary" onClick={handleViewClick}>Done By Production</Button>;
};


// Status update for "Delivery Pickup"
const DelColumn = ({ row, orderStatuses, onTrackNoUpdate  }: { row: any; orderStatuses: Record<number, string>;  onTrackNoUpdate: (orderId: number, trackNo: string) => void; }) => {
  const [status, setStatus] = useState(orderStatuses[row.original.id]);
  const [showModal, setShowModal] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setTrackingNumber("");
  };

  const handleViewClick = async () => {
    if (!trackingNumber) {
      alert("Please enter a courier tracking number.");
      return;
    }

    try {
      // Submit tracking number
      const trackingResponse = await fetch(
        `https://backend.inkox.com/api/trackNo/${row.original.id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Accept: "application/json" },
          body: JSON.stringify({ track_no: trackingNumber }),
        }
      );

      if (!trackingResponse.ok) throw new Error("Failed to post tracking number.");

      // Update order status
      const statusResponse = await fetch(
        `https://backend.inkox.com/api/orderStatus/${row.original.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json", Accept: "application/json" },
          body: JSON.stringify({ orderStatus: "Departed from Inkox/with delivery partner" }),
        }
      );

      if (!statusResponse.ok) throw new Error("Failed to update order status.");

      alert("Order status updated and tracking number submitted successfully!");

      onTrackNoUpdate(row.original.id, trackingNumber);
      setStatus("Departed from Inkox/with delivery partner");
      handleCloseModal();
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing your request.");
    }
  };

  if (status === "Departed from Inkox/with delivery partner" || status === "Delivered") {
    return <span>Sent</span>;
  }

  if (orderStatuses[row.original.id] === "Ready For Dispatched") {
    return (
      <>
        <Button className="btn btn-primary" onClick={handleOpenModal}>
          Delivery Pickup
        </Button>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Courier Tracking Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Tracking Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter courier tracking number"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
            <Button variant="primary" onClick={handleViewClick}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return null;
};


const Production: React.FC = () => {
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [orderStatuses, setOrderStatuses] = useState<Record<number, string>>({});
  const [assignedOrders, setAssignedOrders] = useState<number[]>([]);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [qcData, setQcData] = useState<QCData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<string>("All");
  const [departedStatusFilter, setDepartedStatusFilter] = useState<string>("All Status");


  const handleStatusChange = async (orderId: number, status: string) => {

    // Update orderStatus in assignments
    const updatedAssignments = assignments.map((assignment) =>
      assignment.order.id === orderId
        ? { ...assignment, order: { ...assignment.order, orderStatus: status } }
        : assignment
    );

    setAssignments(updatedAssignments); // Update state

    // Also update local orderStatuses
    setOrderStatuses((prevStatuses) => ({
      ...prevStatuses,
      [orderId]: status,
    }));

  };

  useEffect(() => {
    const fetchAssignments = async () => {
      try {

        const vendorIdStr = sessionStorage.getItem("vendorId");
        if (!vendorIdStr) {
          console.error("Error: vendorId not found in sessionStorage");
          return;
        }

        const vendorsId = parseInt(vendorIdStr, 10);
        if (isNaN(vendorsId)) {
          console.error("Error: vendorId is not a valid number");
          return;
        }
        const response = await fetch(`https://backend.inkox.com/api/get-assignments/${vendorsId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch assignments.");
        }
        const data = await response.json();
        const filteredAssignments = data.data.filter(
          (assignment: Assignment) => assignment.status === "accepted"
        );
        console.log(filteredAssignments);
        setAssignments(filteredAssignments);
        const statusMap: Record<number, string> = {};
        filteredAssignments.forEach((assignment: Assignment) => {
          statusMap[assignment.order.id] = assignment.order.orderStatus; // Assign orderStatus to orderId
        });

        setOrderStatuses(statusMap);
      } catch (err) {
        setError("Failed to fetch assignments.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssignments();
  }, []);

  // Fetch QC Portal Data
  useEffect(() => {
    const fetchQCData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/qc-portal");
        if (!response.ok) {
          throw new Error("Failed to fetch QC data.");
        }
        const data = await response.json();

        // Parse mock_ss and designer_final fields to arrays
        const formattedQCData = data.map((qc: QCData) => ({
          ...qc,
          mock_ss: JSON.parse(qc.mock_ss),
          designer_final: JSON.parse(qc.designer_final),
        }));

        setQcData(formattedQCData);
      } catch (err) {
        console.error("Error fetching QC Data:", err);
      }
    };

    fetchQCData();
  }, []);



  const handleDownload = async (file: string) => {
    try {
      const response = await fetch(`https://backend.inkox.com/storage/designer_portal/designer_upload_main/${file}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.split("/").pop() || "downloaded-file";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };


  const filteredAssignments = assignments.filter((assignment: Assignment) => {
    const matchesOrderType =
      filterType === "All" || assignment.order.cartData.some((cartItem) => cartItem.orderType === filterType);

    const matchesDepartedStatus =
      departedStatusFilter === "All Status" || assignment.order.orderStatus === departedStatusFilter;

    return matchesOrderType && matchesDepartedStatus;
  });


  const handleTrackNoUpdate = (orderId: number, trackNo: string) => {
    setAssignments((prevAssignments) =>
      prevAssignments.map((assignment) =>
        assignment.order.id === orderId
          ? { ...assignment, order: { ...assignment.order, track_no: trackNo } }
          : assignment
      )
    );
  };


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Production", path: "/apps/ecommerce/prod", active: true },
        ]}
        title={"Production"}
      />

      <Row>

        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <form className="row gy-2 gx-2 align-items-center justify-content-lg-start">
                    <div className="col-auto">
                      <span>Order Type:</span>
                      <Form.Select className="mb-3" value={filterType} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFilterType(e.target.value)}>
                        <option value="All">All</option>
                        <option value="screenPrinting">Screen Printing</option>
                        <option value="embroidery">Embroidery</option>
                      </Form.Select>
                    </div>
                  </form>
                </Col>

                <Col lg={6}>
                  <form className="row gy-2 gx-2 align-items-center justify-content-lg-start">
                    <div className="col-auto">
                      <span>Departed Status:</span>
                      <Form.Select className="mb-3" value={departedStatusFilter} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setDepartedStatusFilter(e.target.value)}>
                        <option value="All Status">All Status</option>
                        <option value="Ready For Dispatched">Delivery PickUp</option>
                        <option value="Departed from Inkox/with delivery partner">Sent</option>
                      </Form.Select>
                    </div>
                  </form>
                </Col>
              </Row>
              <Table responsive bordered className="mb-0">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Order Type</th>
                    <th>Mock</th>
                    <th>Purchase Order</th>
                    <th>Work Order</th>
                    <th>Designer Final</th>
                    <th>Completed</th>
                    <th>Departed</th>
                    <th>Track No</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAssignments.map((assignment) => {
                    // Match QC Data with order.id
                    const matchedQC = qcData.find(qc => qc.orderId === assignment.order.id);

                    return (
                      <tr key={assignment.id}>
                        <td>{assignment.order.id}</td>
                        <td>{assignment.order.cartData[0]?.orderType || "N/A"}</td>

                        {/* Mock Images */}
                        <td>
                          {assignment.order.cartData.map((product) => (
                            <div key={product.id} style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px", marginBottom: "10px" }}>
                              <div style={{ display: "flex", gap: "10px" }}>
                                {product.imagePreviews.map((img) => (
                                  <div key={img.part}>
                                    <p>{img.part}</p>
                                    <img src={img.preview} alt={img.part} width="100" />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </td>

                        {/* Purchase Order Link */}
                        <td>
                          {matchedQC?.poc ? (
                            <a
                              href={`https://backend.inkox.com/storage/${matchedQC.poc}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View POC
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>

                        {/* Work Order Link */}
                        <td>
                          {matchedQC?.work_order ? (
                            <a
                              href={`https://backend.inkox.com/storage/${matchedQC.work_order}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Work Order
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>

                        {/* Designer Final Files */}
                        <td>
                          {matchedQC?.designer_final ? (
                            Array.isArray(matchedQC.designer_final)
                              ? matchedQC.designer_final.map((url: string, index: number) => (
                                <button
                                  key={index}
                                  onClick={() => handleDownload(url)}
                                  style={{
                                    display: "block",
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                  title={`https://backend.inkox.com/storage/designer_portal/designer_upload_main/${url}`}
                                >
                                  Download File {index + 1}
                                </button>
                              ))
                              : (() => {
                                try {
                                  const files = JSON.parse(matchedQC.designer_final);
                                  if (!Array.isArray(files)) throw new Error("Invalid JSON format");

                                  return files.map((url: string, index: number) => (
                                    <button
                                      key={index}
                                      onClick={() => handleDownload(url)}
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        background: "none",
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                      title={`https://backend.inkox.com/storage/designer_portal/designer_upload_main/${url}`}
                                    >
                                      Download File {index + 1}
                                    </button>
                                  ));
                                } catch (error) {
                                  console.error("Error parsing designer_final:", error);
                                  return <span style={{ color: "red" }}>Invalid file data</span>;
                                }
                              })()
                          ) : (
                            "N/A"
                          )}
                        </td>


                        <td>
                          {assignment.order.orderStatus === "Ready For Dispatched" ? (
                            <span>Completed</span>
                          ) : assignment.order.orderStatus === "Departed from Inkox/with delivery partner" ? (
                            <span>Completed</span>
                          ) : (
                            <ActionColumn
                              row={{ original: assignment.order }}
                              onStatusChange={handleStatusChange}
                            />
                          )}
                        </td>

                        <td>
                          {assignment.order.orderStatus === "Departed from Inkox/with delivery partner" ? (
                            <span>Sent</span>
                          ) : (
                            <DelColumn
                              row={{ original: assignment.order }}
                              orderStatuses={orderStatuses}
                              onTrackNoUpdate={handleTrackNoUpdate} 
                            />
                          )}
                        </td>

                        <td>
                        <span>{assignment.order.track_no}</span>
                        </td>

                        {/* <td>{new Date(assignment.order.created_at).toLocaleDateString()}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Production;
