import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Form } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";



interface Blog {
  id: number;
  title: string;
  description: string;
  image: string;
  author_id: number;
  date: string;
  add_description: string;
  catg_id: number;
}

const Blogs = () => {
   const [blogs, setBlogs] = useState<Blog[]>([]);
  var authorId = sessionStorage.getItem("authorId");
  if (!authorId) {
    authorId = "2";
}


  const fetchBlogs = async () => {
    try {
      let url = `https://backend.inkox.com/api/blogs-auth/${authorId}`;


      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setBlogs(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  },[])


  const handleBlogAction = (blog: Blog) => {
    const url = `/apps/ecommerce/blogs-edit?id=${blog.id}`;
    window.open(url, "_blank"); // Opens in a new tab
  };



  const handleDeleteClick = async (id: any) => {
    if (window.confirm("Are you sure you want to delete this Blog?")) {
      try {
        const response = await fetch(`https://backend.inkox.com/api/blogs/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          alert("Blog deleted successfully");
          fetchBlogs();
        } else {
          throw new Error("Failed to delete the blog");
        }
      } catch (error) {
        console.error("There was an error deleting the blog!", error);
        alert("Failed to delete the blog");
      }
    }
  };


  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/blogs" },
          { label: "Blogs", path: "/apps/ecommerce", active: true },
        ]}
        title={"Blogs"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="justify-content-between">
                <Col className="col-auto">
                </Col>

                <Col className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <Button className="btn btn-success waves-effect waves-light me-1">
                      <i className="mdi mdi-cog"></i>
                    </Button>

                    <Link
                      to="/apps/ecommerce/blogs-add"
                      className="btn btn-danger waves-effect waves-light"
                    >
                      <i className="mdi mdi-plus-circle me-1"></i> Add New
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
           {blogs.map((blog) => (
         <>
               <Col  className="cls"  key={blog.id} md={6} xl={3}>
                 <Card className="product-box">
                   <Card.Body>
                     <div className="product-action">
                     <Button
       className="btn btn-success btn-xs waves-effect waves-light me-1"
       onClick={() => handleBlogAction(blog)}
     >
       <i className="mdi mdi-pencil"></i>
     </Button>
     
                       <Button
                         onClick={() => handleDeleteClick(blog.id)}
                         className="btn btn-danger btn-xs waves-effect waves-light"
                       >
                         <i className="mdi mdi-close"></i>
                       </Button>
                     </div>
     
                     {/* Product Image */}
                     <div className="bg-light">
                   <div style={{ textAlign: 'center' }}>
                     <img
                       src={`https://backend.inkox.com/storage/${blog.image}`}
                       alt="Product variant"
                       className="img-fluid"
                       style={{ width: '100%', height: 'auto' }}
                     />
                   </div>
                     </div>
     
                     <div className="product-info">
                       <h5 className="font-16 mt-0 sp-line-1">
                       <div
                       >
                           {blog.title}
                         </div>
                       </h5>
                     </div>
                   </Card.Body>
                 </Card>
               </Col>
               </>
                   ))}
           </Row>
    </React.Fragment>
  );
};

export default Blogs;
