import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface ImagePreview {
  part: string;
  preview: string;
}

// Modify OrderDetails to explicitly type img and imgIndex
const StartOrderDetail = () => {
  const location = useLocation();
  const order = location.state?.order;
    const navigate = useNavigate();
// State for uploaded images
const [designerUploaded, setDesignerUploaded] = useState<File[]>([]);
const [designerUploadMain, setDesignerUploadMain] = useState<File[]>([]);

// console.log("order.id",order.id);
const handleUpload = async () => {
  if (!order) {
    alert("Order data is not available");
    return;
  }

  const formData = new FormData();

  if (designerUploaded.length > 0) {
    designerUploaded.forEach((file) => formData.append("designer_uploaded[]", file));
  }

  if (designerUploadMain.length > 0) {
    designerUploadMain.forEach((file) => formData.append("designer_upload_main[]", file));
  }

  try {
    // Step 1: Trigger updateStatus API using fetch
    const statusResponse = await fetch(`https://backend.inkox.com/api/orders/${order?.id}/status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: "Submitted" }),
    });

    if (!statusResponse.ok) {
      const errorData = await statusResponse.json();
      console.error("Error response from Status API:", errorData);
      throw new Error(`HTTP error! status: ${statusResponse.status}`);
    }

    const updatedOrder = await statusResponse.json(); // Get updated order from API
    console.log("Order status updated successfully:", updatedOrder);

    // Update the local order object with the new status
    const updatedOrderWithStatus = { ...order, Status: "Submitted" };

    // Step 2: Wrap the updated order in FormData
    formData.append("order_data[]", JSON.stringify(updatedOrderWithStatus));

    // Step 3: Trigger Designer API
    const designerResponse = await fetch("https://backend.inkox.com/api/designer-portal", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json", // Ensure JSON response
      },
    });

    if (!designerResponse.ok) {
      const errorData = await designerResponse.json();
      console.error("Error response from Designer API:", errorData);
      throw new Error(`HTTP error! status: ${designerResponse.status}`);
    }

    const designerData = await designerResponse.json();
    console.log("Designer API Response:", designerData);

    // Redirect to the desired page after both APIs succeed
    alert("Order status updated and upload successful!");
    navigate("/apps/ecommerce/assign-order");
  } catch (error) {
    console.error("Error in API calls:", error);
    alert("Failed to update status or upload data.");
  }
};





if (!order) {
  return <p>No order data available</p>;
}

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/order/details" },
          { label: "Assign Order Detail", path: "/apps/ecommerce/order/details", active: true },
        ]}
        title={`Assign Order Detail - ${order.id}`}
      />

      <Row>
      
        {/* <Col lg={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Order Information</h4>
              <p><strong>Order ID:</strong> {order.id}</p>
              <p><strong>Organization:</strong> {order.nameoforganization}</p>
              <p><strong>Email:</strong> {order.emailAddress}</p>
              <p><strong>Contact:</strong> {order.firstName} {order.lastName}</p>
              <p><strong>Phone:</strong> {order.phone}</p>
              <p><strong>Order Status:</strong> {order.orderStatus}</p>
              <p><strong>Payment Status:</strong> {order.payment_status}</p>
              <p><strong>Total Amount:</strong> {order.payment_amount} {order.payment_currency}</p>
            </Card.Body>
          </Card>
        </Col> */}

        {/* Items in Order */}
        <Col lg={12}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Items</h4>
              <div className="table-responsive">
                <table className="table table-bordered table-centered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Product Name</th>
                      <th>Product Color</th>
                      <th>Image</th>
                      <th>Clipart Images</th>
                      <th>Uploaded Images</th>
                      <th>totalQuantity</th>
                      <th>Quantity With Size</th>
                      <th>Fonts</th>
                      <th>No Of Colors</th>
                      {/* <th>Unit Price</th>
                      <th>Total Price</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {order.cartData.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.productColor}</td>
                        <td>
                          {item.imagePreviews.map((img: ImagePreview, imgIndex: number) => (
                            <img
                              key={imgIndex}
                              src={img.preview}
                              alt={`Product ${img.part}`}
                              style={{ width: "50px", height: "50px", marginRight: "5px" }}
                            />
                          ))}
                        </td>
                        <td>
  {item.clipArtData && item.clipArtData.length > 0 ? (
    item.clipArtData.map((clipart: any, clipIndex: number) => {
      const clipartPath = clipart.clipartPath;
      console.log("Clipart Path:", clipartPath); // Debugging log

      return (
        <div key={clipIndex} style={{ marginBottom: "10px" }}>
          <p style={{ margin: "0", fontWeight: "bold" }}>{clipart.part}</p>
          {clipartPath ? (
            <img
              src={clipartPath}
              alt={`Clipart ${clipart.part}`}
              style={{
                width: "50px",
                height: "50px",
                marginRight: "5px",
                objectFit: "contain",
                border: "1px solid #ddd",
                borderRadius: "4px",
              }}
            />
          ) : (
            <span style={{ fontStyle: "italic", color: "gray" }}>
              No clipart available
            </span>
          )}
        </div>
      );
    })
  ) : (
    <div style={{ fontStyle: "italic", color: "gray" }}>No clipart available</div>
  )}
</td>


                        <td>
  {item.uploadedImageData && item.uploadedImageData.length > 0 ? (
    Array.from(
      new Map(
        item.uploadedImageData.map((img: ImagePreview) => [img.part, img])
      ).values() as Iterable<ImagePreview>
    ).map((uniqueImg: ImagePreview, uniqueIndex: number) => (
      <div key={uniqueIndex} style={{ marginBottom: "10px" }}>
        <p style={{ margin: "0", fontWeight: "bold" }}>{uniqueImg.part}</p>
        <img
          src={uniqueImg.preview}
          alt={`Product ${uniqueImg.part}`}
          style={{ width: "50px", height: "50px", marginRight: "5px" }}
        />
      </div>
    ))
  ) : (
    <div style={{ fontStyle: "italic", color: "gray" }}>
      No Image Is Available
    </div>
  )}
</td>


                        <td>{item.totalQuantity}</td>
                        <td>
          {Object.entries(item.quantities).map(([size, qty], qtyIndex) => (
            <div key={qtyIndex}>
              {size}: {qty}
            </div>
          ))}
        </td>
        <td>{item.selectedFonts}</td>
        <td>
  {item.inkColors && typeof item.inkColors === 'object' ? (
    Object.entries(item.inkColors).map(([key, colors]) => (
      <div key={key} style={{ marginBottom: '10px' }}>
        <strong>{key}:</strong>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          {Array.isArray(colors) && colors.length > 0 ? (
            colors.map((color, index) => (
              <div
                key={`${key}-${index}`}
                style={{
                  display: 'inline-block',
                  width: '40px',
                  height: '40px',
                  backgroundColor: color as string, // Type assertion
                  color: '#fff',
                  textAlign: 'center',
                  lineHeight: '40px',
                  border: '1px solid #000',
                  borderRadius: '4px',
                  fontSize: '10px',
                  overflow: 'hidden',
                }}
              >
                {color as string} {/* Type assertion */}
              </div>
            ))
          ) : (
            <span>No colors available</span>
          )}
        </div>
      </div>
    ))
  ) : (
    <div>No colors available</div>
  )}
</td>


              

                      
                        {/* <td>{item.unitPrice}</td>
                        <td>{item.total_price_with_delivery}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
  
        <Col lg={6}>
        <h3>All Files Upload</h3>
        <FileUploader onFileUpload={(file) => setDesignerUploaded(file)} />
</Col>


<Col lg={6}>
     <h3>Final File Upload</h3>
<FileUploader onFileUpload={(file) => setDesignerUploadMain(file)} />
</Col>

      </Row>

      <Row className="d-flex justify-content-center mb-2">
  <Col lg={12} className="text-center">
  <Button className="btn btn-success" onClick={handleUpload}>
            Upload
          </Button>
  </Col>
</Row>

    </React.Fragment>
  );
};

export default StartOrderDetail;