import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StatisticsWidget4 from "../../../components/StatisticsWidget4";

import avatar1 from "../../../assets/images/users/user-3.jpg";
import avatar2 from "../../../assets/images/users/user-4.jpg";
import avatar3 from "../../../assets/images/users/user-5.jpg";
import avatar4 from "../../../assets/images/users/user-6.jpg";

// Define the type for an order
interface Order {
  orderStatus: string;
  updated_at: string; // Date field for filtering
}

const Statistics: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [onProductionOrders, setOnProductionOrders] = useState<number>(0);
  const [deliveredOrders, setDeliveredOrders] = useState<number>(0);
  const [departedOrders, setDepartedOrders] = useState<number>(0);
  const [readyForDispatchOrders, setReadyForDispatchOrders] = useState<number>(0);

  useEffect(() => {
    fetch("https://backend.inkox.com/api/place-orders")
      .then((response) => response.json())
      .then((data: Order[]) => {
        setOrders(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (orders.length === 0) return;

    // Get selected month in YYYY-MM format
    const selectedMonth = selectedDate.toISOString().slice(0, 7);

    // Filter orders based on the selected month
    const filteredOrders = orders.filter(order => order.updated_at.startsWith(selectedMonth));

    // Count statuses
    setOnProductionOrders(filteredOrders.filter(order => order.orderStatus === "On Production").length);
    setDeliveredOrders(filteredOrders.filter(order => order.orderStatus === "Delivered").length);
    setDepartedOrders(filteredOrders.filter(order => order.orderStatus === "Departed from Inkox/with delivery partner").length);
    setReadyForDispatchOrders(filteredOrders.filter(order => order.orderStatus === "Ready for dispatch").length);
  }, [selectedDate, orders]);

  return (
    <>
      {/* Month Picker */}
      <Row className="mb-3">
        <Col md={4}>
          <label className="fw-bold">Select Month:</label>
          <DatePicker
            selected={selectedDate}
            onChange={(date: Date) => setSelectedDate(date)}
            dateFormat="yyyy-MM"
            showMonthYearPicker
            className="form-control"
          />
        </Col>
      </Row>

      {/* Order Statistics */}
      <Row>
        <Col md={6} xl={3}>
          <StatisticsWidget4
            avatar={avatar1}
            name="On Production"
            position={`${onProductionOrders} Orders`}
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget4
            avatar={avatar2}
            name="Delivered"
            position={`${deliveredOrders} Orders`}
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget4
            avatar={avatar3}
            name="Departed"
            position={`${departedOrders} Orders`}
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget4
            avatar={avatar4}
            name="Ready for Dispatch"
            position={`${readyForDispatchOrders} Orders`}
          />
        </Col>
      </Row>
    </>
  );
};

export default Statistics;
