import React, { useEffect, useState } from "react";
import "./inf.css";

interface ImageMeta {
  file: File;
  preview: string;
  customName: string;
}

interface ImageItem {
  name: string;
  images: ImageMeta[];
}


interface BackendImage {
  path: string;
  name: string;
}

interface BackendRecord {
  id: number;
  name: string;
  images: BackendImage[];
}

const SellerImages: React.FC = () => {
  const [records, setRecords] = useState<ImageItem[]>([]);
  const [backendRecords, setBackendRecords] = useState<BackendRecord[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editName, setEditName] = useState("");
  const [editFiles, setEditFiles] = useState<File[]>([]);
  const [editImages, setEditImages] = useState<ImageMeta[]>([]);
  const [removedImages, setRemovedImages] = useState<string[]>([]);

  const handleAddRecord = () => {
    setRecords([...records, { name: "", images: [] }]);
  };

  const handleNameChange = (index: number, value: string) => {
    const updated = [...records];
    updated[index].name = value;
    setRecords(updated);
  };

  const handleFilesChange = (index: number, files: FileList | null) => {
    if (!files) return;
    const fileArray = Array.from(files);
    const imageMeta: ImageMeta[] = fileArray.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      customName: "",
    }));

    const updated = [...records];
    updated[index].images = [...updated[index].images, ...imageMeta]; // Append images
    setRecords(updated);
  };

  const handleCustomImageNameChange = (
    recordIndex: number,
    imageIndex: number,
    value: string
  ) => {
    const updated = [...records];
    updated[recordIndex].images[imageIndex].customName = value;
    setRecords(updated);
  };

  const submitRecord = async (index: number) => {
    const record = records[index];
    if (!record.name || record.images.length === 0) {
      alert("Name and images are required.");
      return;
    }

    const formData = new FormData();
    formData.append("name", record.name);
    record.images.forEach((img) => {
      formData.append("images[]", img.file);
      formData.append("image_names[]", img.customName || img.file.name);
    });

    try {
      const res = await fetch("https://backend.inkox.com/api/seller-images", {
        method: "POST",
        body: formData,
      });

      if (!res.ok) throw new Error("Failed to upload");

      alert("Uploaded successfully!");
      fetchBackendRecords();
    } catch (err) {
      console.error("Upload error:", err);
      alert("Error uploading images.");
    }
  };

  const fetchBackendRecords = async () => {
    try {
      const res = await fetch("https://backend.inkox.com/api/seller-images");
      const data = await res.json();
      setBackendRecords(data);
    } catch (err) {
      console.error("Error fetching backend records:", err);
    }
  };

  useEffect(() => {
    fetchBackendRecords();
  }, []);

  const handleDelete = async (id: number) => {
    if (!window.confirm("Are you sure you want to delete this record?")) return;
    try {
      const res = await fetch(`https://backend.inkox.com/api/seller-images/${id}`, {
        method: "DELETE",
      });
      if (!res.ok) throw new Error("Delete failed");
      alert("Record deleted");
      fetchBackendRecords();
    } catch (err) {
      console.error("Delete error:", err);
    }
  };

  const startEdit = (index: number, record: BackendRecord) => {
    setEditIndex(index);
    setEditName(record.name);
    setEditFiles([]);
  };

  const handleUpdate = async (id: number) => {
    const formData = new FormData();
    formData.append("name", editName);
    editImages.forEach((img) => {
      formData.append("images[]", img.file);
      formData.append("image_names[]", img.customName || img.file.name);
    });

    try {
      const res = await fetch(`https://backend.inkox.com/api/seller-images/${id}`, {
        method: "POST",
        body: formData,
      });
      if (!res.ok) throw new Error("Update failed");
      alert("Record updated");
      setEditIndex(null);
      fetchBackendRecords();
    } catch (err) {
      console.error("Update error:", err);
    }
  };

  return (
    <div>
      <h2>Seller Images</h2>

      {records.map((record, index) => (
        <div
          key={index}
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid #ccc",
            paddingBottom: "10px",
          }}
        >
          <label>
            Name:{" "}
            <input
              type="text"
              value={record.name}
              onChange={(e) => handleNameChange(index, e.target.value)}
              style={{ marginRight: "10px" }}
            />
          </label>

          <label>
            Uploader (Multiple):{" "}
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => handleFilesChange(index, e.target.files)}
            />
          </label>

          {record.images.length > 0 && (
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {record.images.map((img, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "120px",
                  }}
                >
                  <img
                    src={img.preview}
                    alt={`Preview ${i}`}
                    style={{ width: "100px", borderRadius: "8px" }}
                  />
                  <input
                    type="text"
                    placeholder="Image name"
                    value={img.customName}
                    onChange={(e) =>
                      handleCustomImageNameChange(index, i, e.target.value)
                    }
                    style={{
                      marginTop: "4px",
                      fontSize: "12px",
                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </div>
          )}

          <button onClick={() => submitRecord(index)} style={{ marginTop: "10px" }}>
            Submit Record
          </button>
        </div>
      ))}

      <button onClick={handleAddRecord} style={{ marginTop: "10px" }}>
        + Add Record
      </button>

      {backendRecords.length > 0 && (
        <div style={{ marginTop: "30px" }}>
          <h3>Saved Records</h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={thStyle}>#</th>
                <th style={thStyle}>Name</th>
                <th style={thStyle}>Images</th>
                <th style={thStyle}>Action</th>
              </tr>
            </thead>
            <tbody>
              {backendRecords.map((record, index) => (
                <tr key={record.id}>
                  <td style={tdStyle}>{index + 1}</td>
                  <td style={tdStyle}>
                    {editIndex === index ? (
                      <input
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        style={{ padding: "4px", width: "100%" }}
                      />
                    ) : (
                      record.name
                    )}
                  </td>
                  <td style={tdStyle}>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
                    {record.images.map((img, i) => {
  const fullPath = `https://backend.inkox.com/${img.path}`;
  const isRemoved = removedImages.includes(img.path);

  return (
    <div key={i} style={{ position: "relative", marginRight: "8px", textAlign: "center" }}>
      {!isRemoved && (
        <>
          <img
            src={fullPath}
            alt={img.name}
            style={{ width: "50px", borderRadius: "4px" }}
          />
          <div style={{ fontSize: "12px", marginTop: "4px" }}>{img.name}</div>
          {editIndex === index && (
  <button
    onClick={() => {
      setRemovedImages((prev) => [...prev, img.path]);
    }}
    style={{
      position: "absolute",
      top: "-5px",
      right: "-5px",
      background: "red",
      color: "white",
      border: "none",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      fontSize: "12px",
      cursor: "pointer",
    }}
  >
    ×
  </button>
)}
        </>
      )}
    </div>
  );
})}

                    </div>

                    {editIndex === index && (
                      <div style={{ marginTop: "10px" }}>
  <input
    type="file"
    accept="image/*"
    multiple
    onChange={(e) => {
      const files = Array.from(e.target.files || []);
      const withMeta = files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        customName: "",
      }));
      setEditImages((prev) => [...prev, ...withMeta]);
    }}
  />
  {editImages.length > 0 && (
    <div style={{ display: "flex", gap: "10px", marginTop: "10px", flexWrap: "wrap" }}>
      {editImages.map((img, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "120px",
          }}
        >
          <img
            src={img.preview}
            alt={`New ${i}`}
            style={{ width: "100px", borderRadius: "8px" }}
          />
          <input
            type="text"
            placeholder="Image name"
            value={img.customName}
            onChange={(e) => {
              const updated = [...editImages];
              updated[i].customName = e.target.value;
              setEditImages(updated);
            }}
            style={{ marginTop: "4px", fontSize: "12px", width: "100%" }}
          />
        </div>
      ))}
    </div>
  )}
</div>
                    )}
                  </td>
                  <td style={tdStyle}>
                    {editIndex === index ? (
                      <>
                        <button
                          onClick={() => handleUpdate(record.id)}
                          style={{ marginRight: "8px" }}
                        >
                          Save
                        </button>
                        <button onClick={() => setEditIndex(null)}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => startEdit(index, record)}
                          style={{ marginRight: "8px" }}
                        >
                          Edit
                        </button>
                        <button onClick={() => handleDelete(record.id)}>Delete</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const thStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  background: "#f2f2f2",
  textAlign: "left" as const,
};

const tdStyle = {
  border: "1px solid #ddd",
  padding: "8px",
};

export default SellerImages;
