import React, { FC, useEffect, useState } from 'react';
import './Po.css';

import { colorHexMap } from './colorlibrarygarments';
import logo from "../../../assets/images/ink_logo.png";
import web from "../../../assets/images/web.png";
import mail from "../../../assets/images/mail.png";
import phone from "../../../assets/images/phone.png";
import line from "../../../assets/images/line.png";
import Header from './Header';
import Footer from './Footer';
import favicon from '../../../assets/images/favicon.png';

interface POrder {
    id: number;
    nameoforganization: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phone: string;
    deliveryFirstName: string;
    deliveryLastName: string;
    organization: string;
    country: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    cartData: Array<{
      id: number;
      productName: string;
      productColor: string;
      imagePreviews: Array<{ preview: string; part: string }>;
      inkColors: Record<string, string[]>;
      quantities: Record<string, number>;
      totalQuantity: number;
      unitPrice: string;
      totalPrice: string;
      orderType: string;
      canvas_price: string;
      Delivery_date: Record<string, string>;
    }>;
    orderStatus: string;
    payment_status: string;
    payment_amount: string;
    payment_currency: string;
  }

interface PoProps {
    orderId: number;
  }


const PurchaseOrder: FC<PoProps>  = ({ orderId }) => {
    const [pOrder, setPOrder] = useState<POrder | null>(null);
        const [selectedId, setSelectedId] = useState<number | null>(orderId);
          const [loading, setLoading] = useState<boolean>(true);
          const [error, setError] = useState<string | null>(null);


         useEffect(() => {
            const fetchWorkOrders = async (): Promise<void> => {
              try {
                const response = await fetch('https://backend.inkox.com/api/place-orders');
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: POrder[] = await response.json();
                const filteredOrder = data.find((order) => order.id === orderId);
                setPOrder(filteredOrder || null);
              } catch (error) {
                setError(error instanceof Error ? error.message : 'An error occurred');
              } finally {
                setLoading(false);
              }
            };
        
            fetchWorkOrders();
          }, [orderId]);

          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error: {error}</div>;


        //   const filteredWorkOrders = selectedId
        //   ? pOrders.filter((order) => order.id === selectedId)
        //   : pOrders;


        //   console.log("Purchase Order :", filteredWorkOrders);

        // Safely destructuring `pOrder` now that it's guaranteed to be non-null
        if (!pOrder) return <div>No order found for this ID.</div>;
  const {
    deliveryFirstName,
    deliveryLastName,
    address,
    city,
    state,
    zip,
    country,
    cartData,
    orderStatus,
    payment_amount,
    payment_currency,
  } = pOrder;

  const subtotal = cartData.reduce((acc, item) => {
    const percentage = item.orderType === 'embroidery' ? 0.2 : 0.3;
    const unitPrice = (parseFloat(item.canvas_price) * percentage).toFixed(2); // Calculate 30% of canvas price
    const totalPrice = (parseFloat(unitPrice) * item.totalQuantity); // Calculate total price for the item
    return acc + totalPrice; // Add this item's total price to the accumulator
  }, 0); // Initial value of accumulator is a number (0)
  
  // Format the subtotal to two decimal places
  const formattedSubtotal = subtotal.toFixed(2);
  
  // Assuming no additional charges, TOTAL equals SUBTOTAL
  const total = formattedSubtotal;
          

  return (
    <div className='main-purchase-order'>
    <div className="purchase-order-container" style={{ padding: "20px" }}>
 
      {/* <Header/> */}
    <div className="purchase-order">
    <div className="invoice-header">
                                                <div className="logo-section">
                                                  <img src={logo} className="logo-invoice" alt="Logo" />
                                                  <p>All Printing Needs</p>
                                                </div>
                                                <div className="company-details">
                                                  {/* <p>150 Milner Ave. Unit #49,</p>
                                                  <p>Scarborough, ON, M1S 3R3 </p> */}
                                                  <p>+123-456-7890<img src={phone} width={'15px'}/></p>
                                                  <p>info@inkox.com<img src={mail} width={'15px'}/></p>
                                                  <p>www.inkox.com <img src={web} width={'15px'}/></p>
                                                </div>
                                              </div>
                                              <div className=""><img src={line} width={'100%'}/></div>
      <div className="header">

        <div className="title">
          <h1>Purchase Order</h1>
          <p>Date: {new Date().toLocaleDateString()}</p>
          <p>P.O. #:  {pOrder.id}</p>
        </div>
      </div>
      <div className="info-section">
        <div className="info-box">
          <h3>[Company Name]</h3>
          <p>[Street Address]</p>
          <p>[City, ST, ZIP]</p>
          <p>Phone: [000-000-0000]</p>
          <p>Fax: [000-000-0000]</p>
        </div>
        <div className="info-box">
          <h3>Vendor</h3>
          <p>[Company Name]</p>
          <p>[Website]</p>
          <p>[Street Address]</p>
          <p>[City, ST, ZIP]</p>
          <p>Phone: [000-000-0000]</p>
        </div>
        <div className="info-box">
            <h3>Ship To</h3>
            <p>
              Name: {deliveryFirstName} {deliveryLastName}
            </p>
            <p>Address: {address}</p>
            <p>City: {city}, {state}</p>
            <p>ZIP: {zip}</p>
            <p>Country: {country}</p>
          </div>
      </div>

      {/* Image Previews Section */}
    <div className="image-previews">
      <h2>Item Previews</h2>
      <div className="preview-images">
        {cartData.map((item) =>
          item.imagePreviews.map((preview, i) =>
            preview.preview ? (
              <div key={`${item.id}-${i}`} className="preview-image">
                <p>{preview.part}</p>
                <img
                  src={preview.preview}
                  alt={`${item.productName} - ${preview.part}`}
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
            ) : null
          )
        )}
      </div>
    </div>

      <div className="items-section">
        <table className="items-table">
          <thead>
            <tr>
              <th>Item #</th>
              <th>Description</th>
              <th>QTY</th>
              <th>Unit Price</th>
              <th>Product Color</th>
              <th>Printing Type</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
          {cartData.map((item: any, index) => {

       const percentage = item.orderType === 'embroidery' ? 0.2 : 0.3;
        // Calculate 30% of the canvas price
        const unitPrice = (parseFloat(item.canvas_price) * percentage).toFixed(2);
        const totalPrice = (parseFloat(unitPrice) * item.totalQuantity).toFixed(2); // Calculate total price based on new unit price


        const colorName = Object.keys(colorHexMap).find((key) => {
          const colorKey = key as keyof typeof colorHexMap;
          return colorHexMap[colorKey].hex.toLowerCase() === item.productColor.toLowerCase();
        });
  
        return (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.productName}</td>
            <td>{item.totalQuantity}</td>
            <td>${unitPrice}</td>
            <td style={{ textAlign: 'left', position: 'relative' }}>
  {colorName ? (
    <div
      style={{
        position: 'relative',
        paddingLeft: '30px', // Adjust this value based on the desired gap
      }}
    >
      <span>{colorName}</span>
      <span
        style={{
          position: 'absolute',
          left: '0', // Fix the color box to the left of the content
          top: '50%',
          transform: 'translateY(-50%)', // Vertically center the color box
          display: 'inline-block',
          width: '20px',
          height: '20px',
          backgroundColor: item.productColor,
          border: '1px solid #000',
        }}
      ></span>
    </div>
  ) : (
    'Unknown Color'
  )}
</td>
           <td>
           {item.orderType
             .split(/(?=[A-Z])|_/g) // Split camelCase or snake_case into separate words
             .map((word : any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
             .join(' ')}
         </td>

            <td>${totalPrice}</td>
          </tr>
        );
      })}
       {/* Subtotal and Total Rows */}
  <tr>
    <td colSpan={6} style={{ textAlign: 'right', fontWeight: 'bold' }}>SUBTOTAL</td>
    <td colSpan={2} style={{ textAlign: 'right' }}>${formattedSubtotal}</td>
  </tr>
  <tr>
    <td colSpan={6} style={{ textAlign: 'right', fontWeight: 'bold' }}>TOTAL</td>
    <td colSpan={2} style={{ textAlign: 'right' }}>${total}</td>
  </tr>
          </tbody>
        </table>

        <p>
          <a rel="noreferrer" target="_blank" href={`https://backend.inkox.com/storage/work_orders/${orderId}_work_order.pdf`}>
          {`https://backend.inkox.com/storage/work_orders/${orderId}_work_order.pdf`}
          </a>
        </p>
      </div>
      {/* <div className="comments-section">
          <p>Comments</p>
          <table className="total-table">
            <tbody>
              <tr>
                <td>SUBTOTAL</td>
                <td>${formattedSubtotal}</td>
              </tr>
              <tr>
                <td>TOTAL</td>
                <td>${total}</td>
              </tr>
            </tbody>
          </table>
        </div> */}
      <div className="footer">
        
        <p>Date</p>
      </div>
      {/* <div className="demo-link">
        <a href="#">Request a demo of Cin7 today</a>
      </div> */}
    </div>
    {/* <Footer/> */}
    </div>
    <div className="invoice-footer">
                                            <div className="disclaimer">This purchase order has been electronically generated and requires no signature.</div>

                                             
                                             <div className="footer-icon">
                                             <img src={favicon} width={"20px"}/>
                                             </div>
                                           </div>
    </div>
  );
};

export default PurchaseOrder;
