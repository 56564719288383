import React, { useEffect, useState } from "react";
import { Button, Alert, Row, Col } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

// actions
import { resetAuth, loginUser, authApiResponseSuccess } from "../../redux/actions";

// store
import { RootState, AppDispatch } from "../../redux/store";

// components
import { VerticalForm, FormInput } from "../../components/";

import AuthLayout from "./AuthLayout";
import { APICore, setAuthorization } from "../../helpers/api/apiCore";
import { AuthActionTypes } from "../../redux/auth/constants";
import { put } from "redux-saga/effects";

interface UserData {
  email: string;
  pwd: string;
}

/* bottom links */
const BottomLink = () => {
  const { t } = useTranslation();




  return (
    <Row className="mt-3">
      <Col className="text-center">
        {/* <p>
          <Link to={"/auth/forget-password"} className="text-white-50 ms-1">
            {t("Forgot your password?")}
          </Link>
        </p> */}
        <p className="text-white-50">
          {t("Don't have an account?")}{" "}
          <Link to={"/auth/blog-register"} className="text-white ms-1">
            <b>{t("Sign Up")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

/* social links */
const SocialLinks = () => {
  const socialLinks = [
    {
      variant: "primary",
      icon: "facebook",
    },
    {
      variant: "danger",
      icon: "google",
    },
    {
      variant: "info",
      icon: "twitter",
    },
    {
      variant: "secondary",
      icon: "github",
    },
  ];
  return (
    <>
      <ul className="social-list list-inline mt-3 mb-0">
        {(socialLinks || []).map((item, index: number) => {
          return (
            <li key={index} className="list-inline-item">
              <Link
                to="#"
                className={classNames(
                  "social-list-item",
                  "border-" + item.variant,
                  "text-" + item.variant
                )}
              >
                <i className={classNames("mdi", "mdi-" + item.icon)}></i>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const BlogLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
      // const [user, setUser] = useState(null);
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState("");
    const navigate = useNavigate();
const api = new APICore();
  const { user, userLoggedIn, loading, error } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
    })
  );

  // useEffect(() => {
  //   dispatch(resetAuth());
  // }, [dispatch]);

  /*
  form validation schema
  */
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup.string().email().required(t("Please enter Email")),
      pwd: yup.string().required(t("Please enter Password")),
    })
  );
  /*
  handle form submission
  */
  const onSubmit = async (formData: UserData) => {
    // setLoading(true);
    // setError("");

    try {
      const response = await fetch("https://backend.inkox.com/api/author/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
    }

    if (data && data.token) {
        // Store user data and token
        // api.setLoggedInUser(data); // Ensure `api.setLoggedInUser` is correctly defined
        // setAuthorization(data.token);
        sessionStorage.setItem("ubold_user", JSON.stringify(data));
        sessionStorage.setItem("Username", data.role);
        sessionStorage.setItem("authorId", data.id);
        // navigate("/apps/ecommerce/dashboard");
        dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, data));
          // setUser(data); 
    } else {
        throw new Error("Invalid response from server, token not found.");
    }


    } catch (error: any) {
      alert(error.message);
    } finally {
      console.log("false");
    }
  };

  // Determine redirection URL based on username
  const username = sessionStorage.getItem("Username");
  const getRedirectUrl = () => {
       
    if (username === "Author") return "/dashboard-5";
    
    return "/apps/ecommerce/dashboard"; // Default redirection
  };
  

  useEffect(() => {
    if (user) {
        navigate(getRedirectUrl(), { replace: true });
    }
}, [user, navigate]);
  return (
    <>
       {/* {(user) && <Navigate to={getRedirectUrl()} replace />} */}

      <AuthLayout
        helpText={t("Enter your email address and password to access vendor panel.")}
        bottomLinks={
          <Row className="mt-3">
            <Col className="text-center">
              <p className="text-white-50">
                {t("Don't have an account?")}{" "}
                <Link to={"/auth/blog-register"} className="text-white ms-1">
                  <b>{t("Sign Up")}</b>
                </Link>
              </p>
            </Col>
          </Row>
        }
      >
        {error && <Alert variant="danger" className="my-2">{error}</Alert>}

        <VerticalForm onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{ email: "", pwd: "" }}>
          <FormInput label={t("Email")} type="email" name="email" placeholder="Enter your Email" containerClass={"mb-3"} />
          <FormInput label={t("Password")} type="password" name="pwd" placeholder="Enter your password" containerClass={"mb-3"} />

          <div className="text-center d-grid">
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? t("Logging in...") : t("Log In")}
            </Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default BlogLogin;
