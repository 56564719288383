import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components/";

interface AddFontProps {
  categoryName: string; 
  categoryId: string;
  selectedFont?: any; // Optional, only used for editing an existing font
  onFontAddedOrUpdated: (font: any) => void; // Callback function after saving
}

const AddFont: React.FC<AddFontProps> = ({ categoryName, selectedFont, onFontAddedOrUpdated,categoryId }) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [category, setCategory] = useState<string>(categoryName);
  const [featuredFont, setFeaturedFont] = useState<boolean>(selectedFont?.featuredFont === 1);

  // Set up form methods, including validation rules
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Font name is required"),
        SubCategory: yup.string().required("Sub Category is required"),
        featuredFont: yup.boolean().required("Please select an option for Featured Font"),
      })
    ),
    defaultValues: {
      name: selectedFont?.name || "", // Set default values for the form fields
      SubCategory: selectedFont?.sub_category || "",
      featuredFont: selectedFont?.featuredFont === 1,
    },
  });

  const { handleSubmit, register, setValue, formState: { errors } } = methods;

  // Set form values if `selectedFont` changes (for editing)
  useEffect(() => {
    if (selectedFont) {
      setValue("name", selectedFont.name);
      setValue("SubCategory", selectedFont.sub_category);
      setCategory(categoryName);
      setFeaturedFont(selectedFont.featured_font === 1);
    } else {
      setCategory(categoryName); // Set the initial category name when adding
    }
  }, [selectedFont, setValue, categoryName]);

  // Handle file uploads
  const onFileUpload = (files: any[]) => {
    setUploadedFiles(files);
  };

  // Handle form submission for adding/updating a font
  const onSubmit = async (data: any) => {
    if (!uploadedFiles[0] && !selectedFont) {
      toast.error("Please upload a font file.");
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("category", categoryId.toString() || "");
 // Ensure this is a string
      formData.append("sub_category", data.SubCategory);
      formData.append("featured_font", featuredFont ? "1" : "0");
  
      if (uploadedFiles[0]) {
        formData.append("font_file", uploadedFiles[0]); // Corrected field name
      }
  
      const url = selectedFont
        ? `https://backend.inkox.com/api/fonts/${selectedFont.id}`
        : "https://backend.inkox.com/api/fonts";
      const method = selectedFont ? "POST" : "POST"; // Use POST with method override for PUT
      const headers = selectedFont ? { "X-HTTP-Method-Override": "PUT" } : undefined;
  
      const response = await fetch(url, {
        method,
        body: formData,
        headers: headers as HeadersInit | undefined,
      });
  
      if (response.ok) {
        const result = await response.json();
        onFontAddedOrUpdated({
          ...result,
          // Add a cache-busting query parameter to the font URL
          font_path: `https://backend.inkox.com/storage/${result.font_path}`,
        });
      } else {
        throw new Error("Something went wrong with the request");
      }
    } catch (error) {
      console.error("Error saving font:", error);
      toast.error("Error saving font!", { position: "bottom-right" });
    }
  };
  

  return (
    <>
      <ToastContainer />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <FormInput
                  name="name"
                  label="Font Name"
                  placeholder="Enter Font Name"
                  containerClass={"mb-3"}
                  register={register}
                  errors={errors}
                />

                {/* Font Category Field (Always use categoryName prop) */}
                <Form.Group className="mb-3">
                  <Form.Label>Font Categories</Form.Label>
                  <Form.Control
                    type="text"
                    value={category} // This is directly using the categoryName prop
                    disabled // Keep this field disabled
                  />
                </Form.Group>

                <FormInput
                  name="SubCategory"
                  label="Reference"
                  placeholder="Enter Reference"
                  containerClass={"mb-3"}
                  register={register}
                  errors={errors}
                />

                 {/* Featured Font Checkbox */}
                 <Form.Group className="mb-3">
                  <Form.Label>Featured Font</Form.Label>
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="True"
                      checked={featuredFont}
                      onChange={() => setFeaturedFont(true)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="False"
                      checked={!featuredFont}
                      onChange={() => setFeaturedFont(false)}
                    />
                  </div>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Font Uploader
                </h5>
                <FileUploader onFileUpload={onFileUpload} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddFont;
