import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import WorkPage from "./WorkPage";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PurchaseOrder from "./Po";

const QcDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderList = location.state?.order;

  const [designerUploaded, setDesignerUploaded] = useState<File[]>([]);
  const [designerUploadMain, setDesignerUploadMain] = useState<File[]>([]);
  const [poTotalPrice, setPoTotalPrice] = useState<string>("0.00"); // New state

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [designerMainFiles, setDesignerMainFiles] = useState<string[]>(
    JSON.parse(orderList?.designer_upload_main || "[]")
  );
  const [eligibleVendors, setEligibleVendors] = useState<any[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<string>("");
  const [isAssigning, setIsAssigning] = useState<boolean>(false);
  const [assignedVendor, setAssignedVendor] = useState<string>("");
  const orderData = JSON.parse(orderList.order_data[0]);
  const handleFileSelect = (file: File, isSelected: boolean) => {
    setSelectedFiles((prevSelected) =>
      isSelected
        ? [...prevSelected, file]
        : prevSelected.filter((selectedFile) => selectedFile.name !== file.name)
    );
  };

  useEffect(() => {
    fetchEligibleVendors();
  }, []);

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/get-assignments");
        if (!response.ok) throw new Error("Failed to fetch assignments");
  
        const data = await response.json();
        console.log("Assignments Data:", data);
  
        // ✅ Find Assigned Vendor for this Order
        const assignment = data.data.find((item: any) => item.order_id === orderData.id);
        if (assignment) {
          setAssignedVendor(assignment.vendor_id.toString()); // Convert ID to string for dropdown
        }
      } catch (error) {
        console.error("Error fetching assignments:", error);
      }
    };
  
    fetchAssignments();
  }, [orderData.id]);


  const handleFileUploadClick = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected to upload.");
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      const response = await fetch(
        `https://backend.inkox.com/api/upload-designer-main-files`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const responseData = await response.json();

      if (responseData.success) {
        const uploadedFiles = responseData.files; // Assume API returns file paths
        setDesignerMainFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        setSelectedFiles([]); // Clear selected files after upload
        alert("Files uploaded successfully!");
      } else {
        throw new Error("File upload was unsuccessful.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files.");
    }
  };
  
  
  
  console.log("setPoTotalPrice",setPoTotalPrice);
  
  console.log("PoTotalPrice",poTotalPrice);
  
  if (!orderList) {
    return <p>No order data available</p>;
  }


  const handleFileClick = async () => {
    if (!selectedFiles.length) {
      alert("No files selected to upload.");
      return;
    }
  
    const formData = new FormData();
    
    try {
      const orderId = JSON.parse(orderList.order_data[0]).id;
  
      const orderResponse = await fetch(`https://backend.inkox.com/api/place-orders`);
      if (!orderResponse.ok) {
        throw new Error(`Failed to fetch order data: ${orderResponse.statusText}`);
      }
      const orders = await orderResponse.json();
      const currentOrder = orders.find((order: any) => order.id === orderId);
      
      if (!currentOrder) {
        throw new Error("Order not found.");
      }
  
      const poTotalPrice = currentOrder.cartData.reduce((acc: number, item: any) => {
        const percentage = item.orderType === "embroidery" ? 0.2 : 0.3;
        const unitPrice = (parseFloat(item.canvas_price) * percentage).toFixed(2); // Calculate 30% of canvas price
        const totalPrice = (parseFloat(unitPrice) * item.totalQuantity); 
        return acc + totalPrice;
      }, 0).toFixed(2);
  
      // Update order status to "On Production"
      await fetch(`https://backend.inkox.com/api/orders/${orderId}/status`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({ Status: "Approved" }),
      });
  
      await fetch(`https://backend.inkox.com/api/orderStatus/${orderId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({ orderStatus: "On Production" }),
      });
  
      // POST po_total_price and order_id to `po_detail`
      await fetch("https://backend.inkox.com/api/po_detail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          order_id: orderId,
          po_total_price: poTotalPrice,
        }),
      });
      const orderData = JSON.parse(orderList.order_data[0]);
    const mockSS = orderData.cartData[0]?.imagePreviews.map((preview: any) => preview.preview) || [];
    formData.append("orderId", orderId);
    const workOrderElement = document.querySelector(".work-orders-container") as HTMLElement;

    if (workOrderElement) {
      const canvas = await html2canvas(workOrderElement, {
        scale: 1, // Improves resolution
        useCORS: true, // Ensures cross-origin images are included
      });
    
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
    
      const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 page width in mm
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 page height in mm
    
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
    
      // Calculate the aspect ratio for scaling
      const imgHeight = (pdfWidth * canvasHeight) / canvasWidth; // Image height in PDF units
      let yPosition = 0;
    
      while (yPosition < canvasHeight) {
        // Create a portion of the canvas for the current page
        const sectionCanvas = document.createElement("canvas");
        sectionCanvas.width = canvasWidth;
        sectionCanvas.height = (pdfHeight * canvasWidth) / pdfWidth; // Height in canvas units that fits one PDF page
    
        const ctx = sectionCanvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(
            canvas,
            0,
            yPosition,
            canvasWidth,
            sectionCanvas.height, // Source height to capture
            0,
            0,
            sectionCanvas.width,
            sectionCanvas.height // Destination height
          );
        }
    
        const sectionImgData = sectionCanvas.toDataURL("image/png");
    
        if (yPosition > 0) {
          pdf.addPage();
        }
        pdf.addImage(sectionImgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    
        yPosition += sectionCanvas.height; // Move to the next section
      }
    
      // const pdfBlob = pdf.output("blob");
    
      const pdfBlob = pdf.output("blob");
const pdfFileName = `${orderList.id}_work_order.pdf`; // Construct the file name using the orderId
const pdfFile = new File([pdfBlob], pdfFileName, { type: "application/pdf" });
      formData.append("work_order", pdfFile);
    }
    
    


    const pOrderElement = document.querySelector(".purchase-order-container") as HTMLElement;
    if (pOrderElement) {


      const canvas = await html2canvas(pOrderElement, {
        scale: 1, // Improves resolution
        useCORS: true, // Ensures cross-origin images are included
      });
    
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
    
      const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 page width in mm
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 page height in mm
    
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
    
      // Calculate the aspect ratio for scaling
      const imgHeight = (pdfWidth * canvasHeight) / canvasWidth; // Image height in PDF units
      let yPosition = 0;
    
      while (yPosition < canvasHeight) {
        // Create a portion of the canvas for the current page
        const sectionCanvas = document.createElement("canvas");
        sectionCanvas.width = canvasWidth;
        sectionCanvas.height = (pdfHeight * canvasWidth) / pdfWidth; // Height in canvas units that fits one PDF page
    
        const ctx = sectionCanvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(
            canvas,
            0,
            yPosition,
            canvasWidth,
            sectionCanvas.height, // Source height to capture
            0,
            0,
            sectionCanvas.width,
            sectionCanvas.height // Destination height
          );
        }
    
        const sectionImgData = sectionCanvas.toDataURL("image/png");
    
        if (yPosition > 0) {
          pdf.addPage();
        }
        pdf.addImage(sectionImgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    
        yPosition += sectionCanvas.height; // Move to the next section
      }
      const pdfBlob = pdf.output("blob");

      // Convert Blob to File
      // const pdfBlob = pdf.output("blob");
      const pdfFileName = `${orderList.id}_purchase_order.pdf`; // Construct the file name using the orderId
      const pdfFile = new File([pdfBlob], pdfFileName, { type: "application/pdf" });
      formData.append("poc", pdfFile);
      // const pdfFile = new File([pdfBlob], "purchase_order.pdf", { type: "application/pdf" });
      // formData.append("poc", pdfFile);
      }

    // Append additional data
    // formData.append("poc", "Point of Contact");
    mockSS.forEach((item: string, index: number) => {
      formData.append(`mock_ss[${index}]`, item); // Send as array
    });
    

    selectedFiles.forEach((file: File, index: number) => {
      formData.append(`designer_final[${index}]`, file.name); // Use file.name as a string
    });


    const response = await fetch("https://backend.inkox.com/api/qc-portal", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to upload files: ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData.success) {
      alert("Files and work order uploaded successfully!");
      setSelectedFiles([]);
    } else {
      throw new Error("File upload was unsuccessful.");
    }
  } catch (error) {
    console.error("Error uploading files:", error);
    alert("Failed to upload files.");
  }
  };
  

  const handleReject = async () => {
     const orderId = JSON.parse(orderList.order_data[0]).id;
    const response =  await fetch(`https://backend.inkox.com/api/orders/${orderId}/status`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({ Status: "Rejected" }),
    });

    if (!response.ok) {
      throw new Error(`Failed to Update Status: ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData.message === "Order status updated successfully") {
      alert("Status Update successfully!");
      navigate("/apps/ecommerce/qc");
    } else {
      throw new Error("Status Update was unsuccessful.");
    }

  }



  // ✅ Fetch Eligible Vendors
  const fetchEligibleVendors = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/vendor-signup");
      if (!response.ok) throw new Error("Failed to fetch vendors");

      const data = await response.json();
      setEligibleVendors(data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  // ✅ Assign Order to Vendor
  const handleAssignVendor = async () => {
    if (!selectedVendor) {
      alert("Please select a vendor before assigning.");
      return;
    }

    try {
      setIsAssigning(true);

      const orderId = JSON.parse(orderList.order_data[0]).id;
      const response = await fetch("https://backend.inkox.com/api/force-assign-order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order_id: orderId, vendor_id: selectedVendor }),
      });

      if (!response.ok) throw new Error("Failed to assign order");

      alert("Order successfully assigned to vendor!");
      navigate("/apps/ecommerce/qc"); // Redirect after assignment
    } catch (error) {
      console.error("Error assigning vendor:", error);
      alert("Failed to assign vendor.");
    } finally {
      setIsAssigning(false);
    }
  };
  
  

  // Parse `order_data`

  const designerUploadedFiles = JSON.parse(orderList.designer_uploaded || "[]");
  // const designerMainFiles = JSON.parse(orderList.designer_upload_main || "[]");

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/order/details" },
          { label: "Qc Detail", path: "/apps/ecommerce/order/details", active: true },
        ]}
        title={`Qc Detail - ${orderList.id}`}
      />
    <WorkPage orderId={orderData.id}/>
    <br/>
    <PurchaseOrder orderId={orderData.id}  />

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Items</h4>
              <div className="table-responsive">
                <table className="table table-bordered table-centered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Product Name</th>
                      <th>Product Color</th>
                      <th>Image</th>
                      <th>Clipart Images</th>
                      <th>Uploaded Images</th>
                      <th>Total Quantity</th>
                      <th>Quantity With Size</th>
                      <th>Fonts</th>
                      <th>No Of Colors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.cartData.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.productColor}</td>
                        <td>
                          {item.imagePreviews.map((img: any, imgIndex: number) => (
                            <img
                              key={imgIndex}
                              src={img.preview}
                              alt={img.part}
                              style={{ width: "50px", height: "50px", marginRight: "5px" }}
                            />
                          ))}
                        </td>
                        <td>
                          {item.clipArtData.map((clipart: any, clipIndex: number) => (
                            <div key={clipIndex}>
                              <p>{clipart.part}</p>
                              {/* <p>{clipart.clipartPath}</p> */}
                              <img
                                src={clipart.clipartPath || ""}
                                alt={`Clipart ${clipart.part}`}
                                style={{ width: "50px", height: "50px", marginRight: "5px" }}
                              />
                            </div>
                          ))}
                        </td>
                        <td>
  {item.uploadedImageData && item.uploadedImageData.length > 0 ? (
    Array.from(
      new Map(
        item.uploadedImageData.map((img: any) => [img.part, img]) // Use `part` as the unique key
      ).values() // Get unique images based on `part`
    ).map((uniqueImg: any, uniqueIndex: number) => (
      <div key={uniqueIndex} style={{ marginBottom: "10px" }}>
        <p style={{ margin: "0", fontWeight: "bold" }}>{uniqueImg.part}</p>
        <img
          src={uniqueImg.preview}
          alt={`Product ${uniqueImg.part}`}
          style={{ width: "50px", height: "50px", marginRight: "5px" }}
        />
      </div>
    ))
  ) : (
    <div style={{ fontStyle: "italic", color: "gray" }}>
      No Image Is Available
    </div>
  )}
</td>



                        <td>{item.totalQuantity}</td>
                        <td>
                          {Object.entries(item.quantities).map(([size, qty]) => (
                            <div key={size}>
                              {size}: {qty}
                            </div>
                          ))}
                        </td>
                        <td>{item.selectedFonts.join(", ") || "None"}</td>
                        <td>
  {Object.entries(item.inkColors as Record<string, string[]>).map(([part, colors]) => (
    <div key={part} style={{ marginBottom: "10px" }}>
      <p style={{ fontWeight: "bold", margin: "0" }}>{part}</p>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
        {colors.length > 0 ? (
          colors.map((color, colorIndex) => (
            <div
              key={`${part}-${colorIndex}`}
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: color,
                border: "1px solid #000",
                marginRight: "5px",
              }}
            ></div>
          ))
        ) : (
          <span style={{ fontStyle: "italic", color: "gray" }}>No colors</span>
        )}
      </div>
    </div>
  ))}
</td>


                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Designer Uploaded Files</h4>
              {designerUploadedFiles.map((file: string, index: number) => (
                <a
                  key={index}
                  href={`https://backend.inkox.com/storage/${file}`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "block" }}
                >
                  File {index + 1}
                </a>
              ))}
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Designer Main Files</h4>
              {designerMainFiles.map((file: string, index: number) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    type="checkbox"
                    className="me-2"
                    onChange={(e) =>
                      handleFileSelect(
                        new File([file], file.split("/").pop() || "unknown"),
                        e.target.checked
                      )
                    }
                  />
                  <a
                    href={`https://backend.inkox.com/storage/${file}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    File {index + 1}
                  </a>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col lg={12}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Assign Order to Vendor</h4>
              <Form>
                <Form.Group controlId="vendorSelect">
                  <Form.Label>Select Vendor</Form.Label>
                  <Form.Select value={selectedVendor || assignedVendor} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedVendor(e.target.value)}>
                    <option value="">-- Select Vendor --</option>
                    {eligibleVendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.fullname} (Machine: {vendor.machine}, Location: {vendor.location})
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Button
                  className="btn btn-primary mt-3"
                  onClick={handleAssignVendor}
                  disabled={isAssigning}
                >
                  {isAssigning ? "Assigning..." : "Assign to Vendor"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col lg={12} className="text-center">
         <FileUploader  onFileUpload={setSelectedFiles}/>
        </Col>
        </Row>

      <Row className="d-flex justify-content-center mb-2">
        <Col lg={4} className="text-center">
          <Button
            className="btn btn-warning"
            onClick={handleFileUploadClick}
          >
            Upload
          </Button>
        </Col>
        <Col lg={4} className="text-center">
          <Button
            className="btn btn-success"
            onClick={handleFileClick}
          >
            Submit
          </Button>
        </Col>
        <Col lg={4} className="text-center">
          <Button
            className="btn btn-danger"
            onClick={handleReject}
          >
            Reject
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}


export default QcDetail;