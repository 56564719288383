import React, { useState } from "react";
import { Button, Alert, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import AuthLayout from "./AuthLayout";

interface UserData {
  name: string;
  email: string;
  pwd: string;
  about_author?: string;
  image?: FileList;
}

const BlogRegister = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required(t("Please enter Fullname")),
      email: yup.string().required(t("Please enter Email")).email(t("Please enter valid Email")),
      pwd: yup.string().required(t("Please enter Password")),
      about_author: yup.string().optional(),
      image: yup.mixed().optional(),
    })
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserData>({
    resolver: schemaResolver,
  });

  const handleFormSubmit: SubmitHandler<UserData> = async (formData) => {
    const formDataToSend = new FormData();
  
    // Append all form fields
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("pwd", formData.pwd);
    
    if (formData.about_author) {
      formDataToSend.append("about_author", formData.about_author);
    }
  
    // Check if an image is selected and append it
    if (formData.image && formData.image.length > 0) {
        formDataToSend.append("image", formData.image.item(0) as Blob); // Use .item(0) to safely extract the file
      }
  
    try {
      const response = await fetch("https://backend.inkox.com/api/author-signup", {
        method: "POST",
        body: formDataToSend,
      });
  
      const data = await response.json();
      if (response.ok) {
        alert("User registered successfully!");
      } else {
        alert("Error: " + data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Something went wrong. Please try again.");
    }
  };
  

  return (
   <>
      <AuthLayout bottomLinks={null}>
      
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Name")}</Form.Label>
              <Form.Control type="text" {...register("name")} placeholder="Enter your name" />
              <p className="text-danger">{errors.name?.message}</p>
            </Form.Group>
          </Col>


        </Row>

        <Row>
        <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control type="email" {...register("email")} placeholder="Enter your email" />
              <p className="text-danger">{errors.email?.message}</p>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control type="password" {...register("pwd")} placeholder="Enter your password" />
              <p className="text-danger">{errors.pwd?.message}</p>
            </Form.Group>
          </Col>


        </Row>

        <Row>

          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("About Author")}</Form.Label>
              <Form.Control as="textarea" {...register("about_author")} placeholder="Tell us about yourself" />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
  <Form.Label>{t("Upload Image")}</Form.Label>
  <Form.Control 
    type="file" 
    accept="image/*" 
    {...register("image")} 
    onChange={(e) => {
      const target = e.target as HTMLInputElement; // Explicitly cast
      if (target.files) {
        setValue("image", target.files);
      }
    }}
  />
</Form.Group>


        <Button type="submit" className="mt-3">{t("Register")}</Button>
      </Form>
      </AuthLayout>
    </>
  );
};

export default BlogRegister;
