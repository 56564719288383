// import React, { useState, useEffect } from "react";
// import { Card } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// interface ToolApparelDetailProps {
//   id: number;
//   name: string;
//   parts: string;
//   created_at: string;
// }

// const ToolApparelDetail = () => {
//   const [toolApparels, setToolApparels] = useState<ToolApparelDetailProps[]>([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("http://127.0.0.1:8000/api/tool-apparels");
//         const data = await response.json();
//         setToolApparels(data);
//       } catch (error) {
//         console.error("Error fetching tool apparels data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-US', {
//       year: 'numeric',
//       month: 'short',
//       day: 'numeric',
//     });
//   };

//   const handleEditClick = (item: ToolApparelDetailProps) => {
//     navigate("/apps/ecommerce/edit-tool-apparel", {
//       state: { detail: item },
//     });
//   };

//   const parseParts = (parts: string) => {
//     try {
//       const parsedParts = JSON.parse(parts);
//       return parsedParts.map((part: any, index: number) => (
//         <div key={index}>
//           <strong>{part.name}:</strong> {part.description}
//         </div>
//       ));
//     } catch (error) {
//       console.error("Error parsing parts data:", error);
//       return "Invalid parts data";
//     }
//   };

//   return (
//     <Card>
//       <Card.Body>
//         <h4 className="header-title mb-3">Tool Apparel Detail</h4>
//         <div className="table-responsive">
//           <table className="table table-borderless table-hover table-nowrap table-centered m-0">
//             <thead className="table-light">
//               <tr>
//                 <th>Id</th>
//                 <th>Name</th>
//                 {/* <th>Parts</th> */}
//                 <th>Created At</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {toolApparels.map((item, i) => (
//                 <tr key={i}>
//                   <td>{item.id}</td>
//                   <td>{item.name}</td>
//                   {/* <td>{parseParts(item.parts)}</td> */}
//                   <td>{formatDate(item.created_at)}</td>
//                   <td>
//                     <button
//                       onClick={() => handleEditClick(item)}
//                       className="btn btn-xs btn-light"
//                     >
//                       <i className="mdi mdi-pencil"></i> Edit
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </Card.Body>
//     </Card>
//   );
// };

// export default ToolApparelDetail;

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./style.css";

interface ToolApparelDetailProps {
  id: number;
  name: string;
  parts: string; // JSON string representing parts
  type: string;
  created_at: string;
}

const ToolApparelDetail = () => {
  const [toolApparels, setToolApparels] = useState<ToolApparelDetailProps[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/tool-apparels");
        const data = await response.json();
        setToolApparels(data);
      } catch (error) {
        console.error("Error fetching tool apparels data:", error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const handleEditClick = (item: ToolApparelDetailProps) => {
    navigate("/apps/ecommerce/edit-tool-apparel", {
      state: { detail: item }, // Passing the selected item via state
    });
  };

  const parseParts = (parts: string, type: string): JSX.Element | string => {
    try {
        const parsedParts = JSON.parse(parts) as Array<{
            name: string;
            ProductImageComponent1?: {
                colors?: { [key: string]: string };
            };
            [key: string]: any;
        }>;

        return (
            <div className="masking">
                {parsedParts.map((part: { name: string; ProductImageComponent1?: { colors?: { [key: string]: string } }; [key: string]: any }, index: number) => {
                    const imageKey = `ProductImageComponent${index + 1}`;
                    const productImageComponent = part[imageKey];

                    return (
                        <div key={index} style={{ marginBottom: "10px" }}>
                            <strong>{part.name}:</strong>

                            {type === "Masking" && productImageComponent?.image && (
                                <div className="masking">
                                    <img
                                        src={`https://backend.inkox.com${productImageComponent.image}`}
                                        alt={`${part.name} Image`}
                                        className="img-setting"
                                        style={{ width: "100px", marginTop: "5px" }}
                                    />
                                </div>
                            )}

                            {type === "Images" && productImageComponent?.colors && (
                                <div className="masking">
                                    {Object.entries(productImageComponent.colors).map(([colorName, colorImageUrl], colorIndex) => (
                                        <div key={colorIndex} style={{ marginBottom: "5px" }}>
                                            <strong>Color:</strong> {colorName}
                                            {colorImageUrl && (
                                                <img
                                                    src={`https://backend.inkox.com${colorImageUrl}`}
                                                    alt={`${colorName} Image`}
                                                    className="img-setting"
                                                    style={{ width: "100px", marginTop: "5px" }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    } catch (error) {
        console.error("Error parsing parts data:", error);
        return "Invalid parts data";
    }
};



  const deleteApparel = async (id: number) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Canvas ID?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/tool-apparel/delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert('Canvas ID deleted successfully');
      } else {
        alert('Failed to delete Canvas ID');
      }
    } catch (error) {
      alert('An error occurred while deleting the size.');
      console.error(error);
    }
  };
  
  

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title mb-3">Canvas Id Detail</h4>
        <div className="table-responsive">
          <table className="table table-borderless table-hover table-nowrap table-centered m-0">
            <thead className="table-light">
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Parts Images</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {toolApparels.map((item, i) => (
                <tr className="t-row" key={i}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td className="img-setting">{parseParts(item.parts,item.type)}</td>
                  <td>{formatDate(item.created_at)}</td>
                  <td>
                    <button
                      onClick={() => handleEditClick(item)}
                      className="btn btn-xs btn-light"
                    >
                      <i className="mdi mdi-pencil"></i> Edit
                    </button>
                    <button
                      onClick={() => deleteApparel(item.id)}
                      className="btn btn-xs btn-danger"
                    >
                      <i className="mdi mdi-bin"></i> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ToolApparelDetail;