import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";

interface ImagePreview {
  part: string;
  preview: string;
}

const VendorOrderDetail = () => {
  const [order, setOrder] = useState<{ cartData: any[] } | null>(null);
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("id");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`https://backend.inkox.com/api/place-orders/${productId}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [productId]);

  if (!order) {
    return <p>No order data available</p>;
  }

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/order/details" },
          { label: "Order Detail", path: "/apps/ecommerce/order/details", active: true },
        ]}
        title={`Order Detail - ${productId}`}
      />

      {order.cartData.map((item: any, index: number) => (
        <Card key={index} className="mb-4 shadow-sm">
          <Card.Body>
            <h4 className="header-title mb-3">Order Details</h4>

            {/* Product Details */}
            <Row>
              <Col md={4}>
                <p><strong>Product Name:</strong> {item.productName}</p>
                <p><strong>Product Color:</strong> {item.productColor}</p>
                <p><strong>Order Type:</strong> {item.orderType}</p>
              </Col>
              <Col md={4}>
                <p><strong>Total Quantity:</strong> {item.totalQuantity}</p>
                <p><strong>Selected Fonts:</strong> {item.selectedFonts}</p>
              </Col>
              <Col md={4}>
                <p><strong>Quantity with Size:</strong></p>
                {Object.entries(item.quantities).map(([size, qty], qtyIndex) => (
                  <p key={qtyIndex}>{size}: {qty}</p>
                ))}
              </Col>
            </Row>

            <hr />

            {/* Product Image */}
            <Row className="mb-3">
              <Col>
                <h5>Product Images</h5>
                <div className="d-flex flex-wrap gap-2">
                  {item.imagePreviews.map((img: ImagePreview, imgIndex: number) => (
                    <img
                      key={imgIndex}
                      src={img.preview}
                      alt={`Product ${img.part}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "6px",
                        objectFit: "cover",
                        border: "1px solid #ddd",
                      }}
                    />
                  ))}
                </div>
              </Col>
            </Row>

            {/* Clipart Images */}
            <Row className="mb-3">
              <Col>
                <h5>Clipart Images</h5>
                {item.clipArtData && item.clipArtData.length > 0 ? (
                  <div className="d-flex flex-wrap gap-2">
                    {item.clipArtData.map((clipart: any, clipIndex: number) => (
                      <div key={clipIndex} className="text-center">
                        <p className="mb-1 fw-bold">{clipart.part}</p>
                        {clipart.clipartPath ? (
                          <img
                            src={clipart.clipartPath}
                            alt={`Clipart ${clipart.part}`}
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "contain",
                              border: "1px solid #ddd",
                              borderRadius: "6px",
                            }}
                          />
                        ) : (
                          <span className="text-muted">No clipart available</span>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="text-muted">No clipart available</span>
                )}
              </Col>
            </Row>

            {/* Uploaded Images */}
            <Row className="mb-3">
              <Col>
                <h5>Uploaded Images</h5>
                {item.uploadedImageData && item.uploadedImageData.length > 0 ? (
                  <div className="d-flex flex-wrap gap-2">
                    {Array.from(new Map(item.uploadedImageData.map((img: ImagePreview) => [img.part, img])).values() as Iterable<ImagePreview>).map((uniqueImg, uniqueIndex) => (
                      <div key={uniqueIndex} className="text-center">
                        <p className="mb-1 fw-bold">{uniqueImg.part}</p>
                        <img
                          src={uniqueImg.preview}
                          alt={`Uploaded ${uniqueImg.part}`}
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="text-muted">No images uploaded</span>
                )}
              </Col>
            </Row>

            {/* Ink Colors */}
            <Row>
              <Col>
                <h5>Ink Colors</h5>
                <div className="d-flex flex-wrap gap-2">
                  {item.inkColors && typeof item.inkColors === "object"
                    ? Object.entries(item.inkColors).map(([key, colors]) => (
                        <div key={key} className="me-3">
                          <strong>{key}:</strong>
                          <div className="d-flex flex-wrap gap-2 mt-1">
                            {Array.isArray(colors) && colors.length > 0 ? (
                              colors.map((color, index) => (
                                <div
                                  key={`${key}-${index}`}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: color as string,
                                    color: "#fff",
                                    textAlign: "center",
                                    lineHeight: "40px",
                                    border: "1px solid #000",
                                    borderRadius: "4px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {color as string}
                                </div>
                              ))
                            ) : (
                              <span className="text-muted">No colors available</span>
                            )}
                          </div>
                        </div>
                      ))
                    : <span className="text-muted">No colors available</span>}
                </div>
              </Col>
            </Row>

          </Card.Body>
        </Card>
      ))}
    </React.Fragment>
  );
};

export default VendorOrderDetail;
