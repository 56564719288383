import React, { useEffect, useState } from "react";

interface QCData {
  orderId: number;
  work_order: string;
  poc: string;
  mock_ss: string;
  designer_final: string;
}

interface ImagePreview {
  part: string;
  preview: string;
}

interface CartItem {
  id: number;
  productName: string;
  designName: string;
  productColor: string;
  totalQuantity: number;
  totalPrice: string;
  orderStatus: string;
  orderType: string;
  imagePreviews: ImagePreview[];
}

interface Order {
  id: number;
  nameoforganization: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  address: string;
  orderStatus: string;
  cartData: CartItem[];
  payment_status: string;
  payment_amount: string;
}

interface Vendor {
  id: number;
  fullname: string;
  location: string;
  contact: string;
  email: string;
}

interface Assignment {
  id: number;
  status: string;
  assigned_at: string;
  expires_at: string;
  remaining_time: string;
  order: Order;
  vendor: Vendor;
}

const Accepted: React.FC = () => {
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [qcData, setQcData] = useState<QCData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch Assignments Data
  useEffect(() => {
    const fetchAssignments = async () => {
      try {

        const vendorIdStr = sessionStorage.getItem("vendorId");
      if (!vendorIdStr) {
        console.error("Error: vendorId not found in sessionStorage");
        return;
      }

      const vendorsId = parseInt(vendorIdStr, 10);
      if (isNaN(vendorsId)) {
        console.error("Error: vendorId is not a valid number");
        return;
      }
        const response = await fetch(`https://backend.inkox.com/api/get-assignments/${vendorsId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch assignments.");
        }
        const data = await response.json();
        const filteredAssignments = data.data.filter(
          (assignment: Assignment) => assignment.status === "accepted"
        );
        setAssignments(filteredAssignments);
      } catch (err) {
        setError("Failed to fetch assignments.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssignments();
  }, []);

  // Fetch QC Portal Data
  useEffect(() => {
    const fetchQCData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/qc-portal");
        if (!response.ok) {
          throw new Error("Failed to fetch QC data.");
        }
        const data = await response.json();

        // Parse mock_ss and designer_final fields to arrays
        const formattedQCData = data.map((qc: QCData) => ({
          ...qc,
          mock_ss: JSON.parse(qc.mock_ss),
          designer_final: JSON.parse(qc.designer_final),
        }));

        setQcData(formattedQCData);
      } catch (err) {
        console.error("Error fetching QC Data:", err);
      }
    };

    fetchQCData();
  }, []);

  if (loading) return <p>Loading assignments...</p>;
  if (error) return <p>{error}</p>;
  if (assignments.length === 0) return <p>No accepted assignments found.</p>;

  return (
    <div>
      <h1>Accepted Assignments</h1>
      {assignments.map((assignment) => {
        // Match QC Data with order.id
        const matchedQC = qcData.find(qc => qc.orderId === assignment.order.id);

        return (
          <div key={assignment.id} style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "20px" }}>
            <h2>Order ID: {assignment.order.id}</h2>
            <p><strong>Organization:</strong> {assignment.order.nameoforganization}</p>
            <p><strong>Customer:</strong> {assignment.order.firstName} {assignment.order.lastName}</p>
            <p><strong>Email:</strong> {assignment.order.emailAddress}</p>
            <p><strong>Phone:</strong> {assignment.order.phone}</p>
            <p><strong>Country:</strong> {assignment.order.country}</p>
            <p><strong>Status:</strong> {assignment.order.orderStatus}</p>
            <p><strong>Payment Status:</strong> {assignment.order.payment_status} - ${assignment.order.payment_amount}</p>

            <h3>Products</h3>
            {assignment.order.cartData.map((product) => (
              <div key={product.id} style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px", marginBottom: "10px" }}>
                <p><strong>Product:</strong> {product.productName}</p>
                <p><strong>Design:</strong> {product.designName}</p>
                <p><strong>Color:</strong> {product.productColor}</p>
                <p><strong>Quantity:</strong> {product.totalQuantity}</p>
                <p><strong>Total Price:</strong> ${product.totalPrice}</p>
                <p><strong>Order Type:</strong> {product.orderType}</p>

                <div style={{ display: "flex", gap: "10px" }}>
                  {product.imagePreviews.map((img) => (
                    <div key={img.part}>
                      <p>{img.part}</p>
                      <img src={img.preview} alt={img.part} width="100" />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <h3>Vendor Information</h3>
            <p><strong>Name:</strong> {assignment.vendor.fullname}</p>
            <p><strong>Location:</strong> {assignment.vendor.location}</p>
            <p><strong>Contact:</strong> {assignment.vendor.contact}</p>
            <p><strong>Email:</strong> {assignment.vendor.email}</p>

            {/* Show QC Document if Order ID Matches */}
            {matchedQC ? (
              <div>
                <h3>QC Documents</h3>
                <p><strong>Work Order:</strong> <a href={`http://backend.inkox.com/storage/${matchedQC.work_order}`} target="_blank" rel="noopener noreferrer">Download</a></p>
                <p><strong>Purchase Order (POC):</strong> <a href={`http://backend.inkox.com/storage/${matchedQC.poc}`} target="_blank" rel="noopener noreferrer">Download</a></p>

        
             
              </div>
            ) : (
              <p style={{ color: "red" }}>No QC document found for this order.</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Accepted;
