import React, { useState, useEffect } from "react";
import "./inf.css";
import { colorHexMap } from "./colorlibrarygarments"; // Import colorHexMap

const ProductCatalog: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [products, setProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [influencerid, setinfluencerid] = useState<string>("");
  const [sellerAccount, setSellerAccount] = useState<any | null>(null);

  const fetchProducts = async () => {
    const url = `https://backend.inkox.com/api/product?page=${page}&per_page=${limit}&influencerid=${influencerid}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setProducts(data.data || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchSellerAccount = async () => {
    // if (!influencerid) return;
  
    try {
      const response = await fetch("https://backend.inkox.com/api/seller-accounts");
      const data = await response.json();
  
      const filtered = data.filter((item: any) => item.inf_id === 1);
      // setinfluencerid(filtered.unq_id);
      setSellerAccount(filtered);
    } catch (error) {
      console.error("Error fetching seller account:", error);
    }
  };
  

  useEffect(() => {
    fetchProducts();
    fetchSellerAccount();
  }, [page, limit, influencerid]);

  const handleProductAction = (product: any) => {
    const url = `/apps/ecommerce/catalog-detail?id=${product.id}`;
    window.open(url, "_blank"); // Opens in a new tab
  };

  const handleRedirect = () => {
    if (selectedProduct && selectedColor) {
      const colorId = (colorHexMap as Record<string, { hex: string; id: string }>)[selectedColor]?.id || "700007"; // Default if no match
      const url = `https://inkox.com/designer?id=${selectedProduct.productCanvasId}&pid=${selectedProduct.id}&colorId=${colorId}&influencerid=${sellerAccount?.[0]?.unq_id || influencerid}`;
      window.open(url, "_blank"); // Opens in a new tab
    } else {
      alert("Please select a color!");
    }
  };
console.log(sellerAccount);
  return (
    <div>
      <h2>Product List</h2>
      <div>
        <label>
        Influencer id:
          <input
            type="text"
            value={sellerAccount?.[0]?.unq_id || influencerid}
            disabled={true}
            onChange={(e) => setinfluencerid(e.target.value)}
          />
        </label>
        <label>
          Page:
          <input
            type="number"
            value={page}
            onChange={(e) => setPage(Number(e.target.value))}
          />
        </label>
        <label>
          Show:
          <select value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={500}>500</option>
          </select>
        </label>
        <button onClick={fetchProducts}>Apply Filters</button>
      </div>

      <table className="border-inf">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Brand</th>
            <th>Product Canvas ID</th>
            <th>Product Colors</th>
            <th>Selling Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => {
            const colors = product.productColor ? Object.keys(JSON.parse(product.productColor)) : [];

            return (
              <tr key={product.id} onClick={() => handleProductAction(product)} style={{ cursor: "pointer" }}>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>{product.brand}</td>
                <td>{product.productCanvasId}</td>
                <td>{colors.join(", ")}</td> {/* Display colors as comma-separated list */}
                <td>{product.sale_price || "N/A"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {selectedProduct && (
        <div className="product-preview">
          <h3>Product Details</h3>
          <p><strong>ID:</strong> {selectedProduct.id}</p>
          <p><strong>Name:</strong> {selectedProduct.name}</p>
          <p><strong>Brand:</strong> {selectedProduct.brand}</p>
          <p><strong>Product Canvas ID:</strong> {selectedProduct.productCanvasId}</p>
          <p><strong>Selling Price:</strong> ${selectedProduct.sale_price || "N/A"}</p>

          <p><strong>Select Product Color:</strong></p>
          <div>
            {selectedProduct.productColor &&
              Object.keys(JSON.parse(selectedProduct.productColor)).map((color) => (
                <label key={color} style={{ display: "inline-block", marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="productColor"
                    value={color}
                    checked={selectedColor === color}
                    onChange={() => setSelectedColor(color)}
                  />
                  <span
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      backgroundColor: (colorHexMap as Record<string, { hex: string; id: string }>)[color]?.hex || "gray",
                      borderRadius: "50%",
                      marginLeft: "5px",
                    }}
                  ></span> {color}
                </label>
              ))}
          </div>

          <button onClick={handleRedirect}>View in Designer</button>
        </div>
      )}
    </div>
  );
};

export default ProductCatalog;
