import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const BlogCategoriesAdd = () => {
  const [image, setImage] = useState<File | null>(null);

  const schemaResolver = yupResolver(
    yup.object().shape({
      catg_name: yup.string().nullable(),
      catg_img: yup.string().nullable(),
    })
  );

  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;



  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const formatDate = (date: any) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0]; // Ensure YYYY-MM-DD format
};

const onSubmit = async (data: any) => {
    var authorId = sessionStorage.getItem("authorId") || "";

    if (!authorId) {
      authorId = "2";
  }

    const formData = new FormData();
    formData.append("catg_name", data.catg_name);
    
    if (image) {
        formData.append("catg_img", image);
    }

    formData.append("author_id", authorId);

    // Debugging: Log formData keys to see if everything is appended
    for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]); // Debugging: Log the key-value pairs
    }

    try {
        const response = await fetch("https://backend.inkox.com/api/blogs-catg", {
            method: "POST",
            body: formData,
            headers: {
                "Accept": "application/json",
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error response from server:", errorData);
            alert(`Failed to create post: ${errorData.message || "Unknown error"}`);
            return;
        }

        const responseData = await response.json();
        console.log("Post created:", responseData);
        alert("Blog post created successfully!");

    } catch (error) {
        console.error("Error creating post:", error);
        alert(`Failed to create post. Error: ${error}`);
    }
};


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/blogs" },
          {
            label: "Add Blog Category",
            path: "/apps/ecommerce/blogs",
            active: true,
          },
        ]}
        title={"Add Blog Category"}
      />

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>
                <FormInput
                  name="catg_name"
                  label="Category Name"
                  placeholder="Enter blog title"
                  containerClass={"mb-3"}
                  register={register}
                  key="catg_name"
                  errors={errors}
                  control={control}
                />

            

                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">Blog Category Image</h5>
                <Form.Group>
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button type="submit" className="btn w-sm btn-success waves-effect waves-light me-1">
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default BlogCategoriesAdd;
