import React, { useState, useEffect } from "react";
import "./inf.css";
import { colorHexMap } from "./colorlibrarygarments";
import { useSearchParams } from "react-router-dom";

const Catalog: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [products, setProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [influencerid, setinfluencerid] = useState<string>("");
  const [sellerAccount, setSellerAccount] = useState<any | null>(null);
  const [sellerData, setSellerData] = useState<any | null>(null);
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("id");
  const [sellerImages, setSellerImages] = useState<any[]>([]);
  const [selectedSellerImageId, setSelectedSellerImageId] = useState<number | null>(null);
  const [updatedPrices, setUpdatedPrices] = useState<{ [key: string]: number }>({});
  const [selectedSellerImageRecord, setSelectedSellerImageRecord] = useState<any | null>(null);


  const fetchProduct = async () => {
    const url = `https://backend.inkox.com/api/product/${productId}`;
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const result = await response.json();

      console.log("API response:", result);

      setProducts([result]);
      setSelectedProduct({
        ...result,
        productColor: JSON.parse(result.productColor),
        sale_price: JSON.parse(result.sale_price),
      });
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const fetchSellerAccount = async () => {
    try {
      const response = await fetch("http://127.0.0.1:8000/api/seller-accounts");
      const data = await response.json();
      const filtered = data.filter((item: any) => item.inf_id === 1);
      setSellerAccount(filtered);
    } catch (error) {
      console.error("Error fetching seller account:", error);
    }
  };

  const fetchSellerProductData = async () => {
    try {
      const response = await fetch("http://127.0.0.1:8000/api/sel-data");
      const data = await response.json();

      const matched = data.find((item: any) => item.prod_id === productId || "");
      if (matched) {
        setSellerData(matched);
      }
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const fetchSellerImages = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/seller-images");
      const data = await response.json();
      setSellerImages(data);
    } catch (error) {
      console.error("Error fetching seller images:", error);
    }
  };


  const fetchSelectedSellerImage = async (id: number) => {
    try {
      const res = await fetch(`https://backend.inkox.com/api/seller-images/${id}`);
      const data = await res.json();
      setSelectedSellerImageRecord(data);
    } catch (err) {
      console.error("Error fetching selected seller image record:", err);
    }
  };


  useEffect(() => {
    if (productId) {
      fetchProduct();
      fetchSellerAccount();
      fetchSellerProductData();
      fetchSellerImages();
    }
  }, [productId]);

  const handleRedirect = () => {
    if (selectedProduct && selectedColor) {
      const colorId =
        (colorHexMap as Record<string, { hex: string; id: string }>)[selectedColor]?.id ||
        "700007";

      const url = `https://inkox.com/designer?id=${selectedProduct.productCanvasId}&pid=${selectedProduct.id}&colorId=${colorId}&influencerid=${sellerAccount?.[0]?.unq_id || influencerid}`;
      window.open(url, "_blank");
    } else {
      alert("Please select a color!");
    }
  };


  const submitToApi = async () => {
    if (!selectedProduct) return alert("Product not loaded");
    if (!selectedSellerImageRecord) return alert("Please select a seller image set");

    // Determine selected colors
    const colorsToSubmit = sellerData?.images?.length > 0 ? selectedColors : [selectedColor];

    if (colorsToSubmit.length === 0) {
      return alert("Please select at least one color.");
    }

    // Collect the updated prices
    const priceToSubmit = updatedPrices;

    // Build the payload
    const payload = {
      name: selectedProduct.name,
      description: selectedProduct.description,
      summary: selectedProduct.summary,
      productColor: colorsToSubmit.reduce((acc: any, color) => {
        acc[color] = selectedProduct.productColor[color];
        return acc;
      }, {}),
      brand: selectedProduct.brand,
      Weight: selectedProduct.Weight,
      Fit: selectedProduct.Fit,
      fitAndSizingGuide: selectedProduct.fitAndSizingGuide,
      price: priceToSubmit,
      seller_images: selectedSellerImageRecord,
    };

    try {
      const res = await fetch("https://backend.inkox.com/api/custom-products", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) throw new Error("Submission failed");

      const data = await res.json();
      alert("Product saved successfully!");
      console.log("Submitted:", data);
    } catch (err) {
      console.error("Error submitting:", err);
      alert("Failed to submit product");
    }
  };


  console.log("selectedProduct:", selectedProduct);

  return (
    <>
      {selectedProduct?.id ? (
        <div className="product-preview">
          <h3>Product Details</h3>
          <p>
            <strong>ID:</strong> {selectedProduct.id}
          </p>
          <p>
            <strong>Name:</strong> {selectedProduct.name}
          </p>
          <p>
            <strong>Description:</strong> {selectedProduct.description}
          </p>
          <p>
            <strong>Summary:</strong> {selectedProduct.summary}
          </p>
          <p>
            <strong>Brand:</strong> {selectedProduct.brand}
          </p>
          <p>
            <strong>Weight:</strong> {selectedProduct.Weight}
          </p>
          <p>
            <strong>Fit:</strong> {selectedProduct.Fit}
          </p>
          <p>
            <strong>fitAndSizingGuide:</strong>  
      <img
      src={`https://backend.inkox.com/${selectedProduct.fitAndSizingGuide}`}
      alt={selectedProduct.name}
      style={{ width: "100px", borderRadius: "8px" }}
    />
          </p>
          {/* <p>
            <strong>Product Canvas ID:</strong> {selectedProduct.productCanvasId}
          </p> */}
          {/* <p>
            <strong>Selling Price:</strong> ${selectedProduct.sale_price?.["OSFA"] || "N/A"}
          </p> */}

          <div style={{ margin: "20px 0" }}>
            <label><strong>Select Seller Image Set:</strong></label>
            <select
              value={selectedSellerImageId ?? ""}
              onChange={(e) => {
                const id = Number(e.target.value);
                setSelectedSellerImageId(id);
                if (id) fetchSelectedSellerImage(id); // 👈 trigger API
              }}
              style={{ marginLeft: "10px", padding: "6px" }}
            >
              <option value="">-- Select Image Set --</option>
              {sellerImages.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {selectedSellerImageRecord?.images?.length > 0 && (
  <div style={{ marginTop: "10px" }}>
    <p><strong>Selected Set Images:</strong></p>
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
    {selectedSellerImageRecord.images.map((img: { path: string; name: string }, index: number) => (
  <div key={index} style={{ textAlign: "center" }}>
    <img
      src={`https://backend.inkox.com/${img.path}`}
      alt={img.name}
      style={{ width: "100px", borderRadius: "8px" }}
    />
    <div style={{ fontSize: "12px", marginTop: "4px" }}>{img.name}</div>
  </div>
))}

    </div>
  </div>
)}

          <p>
            <strong>Select Product Color:</strong>
          </p>
          <div>
            {selectedProduct.productColor &&
              Object.keys(selectedProduct.productColor).map((color) => {
                const lowerCaseColor = color.toLowerCase();
                const colorHex = (colorHexMap as any)[color]?.hex || "gray";
                const thumbnailPath =
                  selectedProduct.thumbnail?.[lowerCaseColor]?.front ||
                  selectedProduct.productImages2?.[lowerCaseColor]?.front;
                const isCheckbox = sellerData?.images?.length > 0;
                return (
                  <label key={color} style={{ display: "inline-block", marginRight: "10px" }}>
                    <input
                      type={isCheckbox ? "checkbox" : "radio"}
                      name="productColor"
                      value={color}
                      checked={isCheckbox ? selectedColors.includes(color) : selectedColor === color}
                      onChange={() => {
                        if (isCheckbox) {
                          setSelectedColors((prev) =>
                            prev.includes(color)
                              ? prev.filter((c) => c !== color)
                              : [...prev, color]
                          );
                        } else {
                          setSelectedColor(color);
                        }
                      }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        backgroundColor: colorHex,
                        borderRadius: "50%",
                        marginLeft: "5px",
                      }}
                    ></span>{" "}
                    {color}
                    {thumbnailPath && (
                      <img
                        src={`https://backend.inkox.com/${thumbnailPath}`}
                        alt={color}
                        style={{ width: "40px", marginLeft: "10px" }}
                      />
                    )}
                  </label>
                );
              })}
          </div>

          <button onClick={handleRedirect}>View in Designer</button>

          {sellerData && (
            <div style={{ marginTop: "20px" }}>
              <h4>Seller Info</h4>
              <p><strong>Seller ID:</strong> {sellerData.sel_id}</p>

              <p><strong>Seller Prices:</strong></p>
              <ul>
                {/* {Object.entries(sellerData.price).map(([size, price]: any) => (
                  <li key={size}>
                    {size}: ${price}
                  </li>
                ))} */}

                {Object.entries(sellerData.price).map(([size, price]: any) => (
                  <div key={size} style={{ marginBottom: "12px", display: "flex", alignItems: "center", gap: "10px" }}>
                    <label style={{ minWidth: "60px" }}>
                      <strong>{size}</strong>
                    </label>

                    {/* Read-only original price */}
                    <input
                      type="number"
                      value={price}
                      readOnly
                      style={{
                        width: "100px",
                        padding: "4px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        backgroundColor: "#f5f5f5",
                        color: "#555"
                      }}
                    />

                    {/* Editable new price */}
                    <input
                      type="number"
                      placeholder="New Price"
                      value={updatedPrices[size] ?? ""}
                      onChange={(e) => {
                        const newPrice = parseFloat(e.target.value) || 0;
                        setUpdatedPrices((prev) => ({
                          ...prev,
                          [size]: newPrice,
                        }));
                      }}
                      style={{
                        width: "100px",
                        padding: "4px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </div>
                ))}



              </ul>

              <p><strong>Seller Images:</strong></p>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {sellerData.images.map((img: string, index: number) => (
                  <img
                    key={index}
                    src={`https://backend.inkox.com/${img}`}
                    alt={`Seller img ${index + 1}`}
                    style={{ width: "120px", borderRadius: "8px" }}
                  />
                ))}
              </div>

              <button onClick={submitToApi} style={{ marginTop: "20px" }}>
                Save Product
              </button>
            </div>


          )}
        </div>
      ) : (
        <p>Loading product...</p>
      )}
    </>
  );
};

export default Catalog;
