import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

const generateUniqueId = () => Math.random().toString(36).substring(2, 6).toUpperCase(); // 4-character ID
const generateTokenId = () => 
  (Math.random().toString(36) + Math.random().toString(36))
    .substring(2, 22)
    .toUpperCase();

const SellerAccount = () => {
  const [step, setStep] = useState(1);
  const [uniqueId, setUniqueId] = useState("");
  const [tokenId, setTokenId] = useState("");
  const { register, handleSubmit, watch, setValue } = useForm();

  console.log(step);

  useEffect(() => {
    const newUniqueId = generateUniqueId();
    const newTokenId = generateTokenId();
    setUniqueId(newUniqueId);
    setTokenId(newTokenId);
    setValue("unique_id", newUniqueId);
    setValue("token_id", newTokenId);
  }, [setValue]);

  const handleNext = () => {
    if (step < 6) {
      setStep(step + 1);
    }
  };

  const onSubmit = async (data: any) => {
    const formData = {
      sel_name: data.name,
      sel_email: data.email,
      sel_number: data.number,
      main_goal: data.goal_inkox ? "Sale on Inkox" : data.goal_website ? "Sale on Own Website" : "",
      bussiness: data.describe_business ? "Business" : data.describe_influencer ? "Influencer" : "",
      online_store: data.online_store,
      ecommerce: data.orders,
      about_us: data.source,
      inf_id: 1, // Replace with dynamic ID if needed
      unq_id: uniqueId,
      token_id: tokenId
    };

    try {
      const response = await fetch("https://backend.inkox.com/api/seller-accounts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        console.log("Response:", result);
        alert("Form submitted successfully!");
      } else {
        console.error("Error:", result);
        alert("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Submission failed. Please try again.");
    }
  };

  return (
    <>
     {/* Unique ID and Token ID in One Row */}
     <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Unique ID</Form.Label>
                  <Form.Control type="text" disabled={true} {...register("unique_id", { required: true })} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Token ID</Form.Label>
                  <Form.Control type="text" disabled={true} {...register("token_id", { required: true })} />
                </Form.Group>
              </Col>
            </Row>
    <Card className="p-4 w-50 mx-auto mt-5">
      <Form onSubmit={handleSubmit((data) => {
  if (step === 5) {  // Ensure submission only happens on Step 5
    onSubmit(data);
  }
})}>
        {step === 1 && (
          <>
            <h5>Step 1: Personal Information</h5>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" {...register("name", { required: true })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number</Form.Label>
              <Form.Control type="tel" {...register("number", { required: true })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" {...register("email", { required: true })} />
            </Form.Group>
          </>
        )}

        {step === 2 && (
          <>
            <h5>Step 2: Main Goal in Inbox</h5>
            <Form.Check type="checkbox" label="Sale on Inkox" {...register("goal_inkox")} />
            <Form.Check type="checkbox" label="Sale on Own Website" {...register("goal_website")} />
          </>
        )}

        {step === 3 && (
          <>
            <h5>Step 3: Describe Yourself or Your Website</h5>
            <Form.Check type="checkbox" label="Influencer" {...register("describe_influencer")} />
            <Form.Check type="checkbox" label="Business" {...register("describe_business")} />
          </>
        )}

        {step === 4 && (
          <>
            <h5>Step 4: Do You Have an Online Store?</h5>
            <Form.Check type="radio" label="Yes" {...register("online_store")} value="Yes" />
            <Form.Check type="radio" label="No" {...register("online_store")} value="No" />
          </>
        )}

        {step === 5 && (
          <>
            <h5>Step 5: Expected E-commerce Orders</h5>
            <Form.Check type="radio" label="0 to 50" {...register("orders")} value="0-50" />
            <Form.Check type="radio" label="50 to 100" {...register("orders")} value="50-100" />
            <Form.Check type="radio" label="100 to 500" {...register("orders")} value="100-500" />
            <Form.Check type="radio" label="500+" {...register("orders")} value="500+" />
            <h5>How Did You Hear About Us?</h5>
            <Form.Check type="radio" label="Facebook" {...register("source")} value="Facebook" />
            <Form.Check type="radio" label="Instagram" {...register("source")} value="Instagram" />
          </>
        )}

        <div className="d-flex justify-content-between mt-3">
          {step > 1 && <Button onClick={() => setStep(step - 1)}>Back</Button>}
          {step < 5 ? (
             <input type="button" onClick={handleNext} value="Next"/>
          ) : (
            <Button type="submit">Submit</Button>
          )}
        </div>
      </Form>
    </Card>
    </>
  );
};

export default SellerAccount;
