import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import SalesChart from "./SalesChart";
import StatisticsChart from "./StatisticsChart";
import IncomeChart from "./IncomeChart";
import Statistics from "./Statistics";


const Dashboard5 = () => {

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Dashboards", path: "/dashboard-5" },
          { label: "Dashboard 5", path: "/dashboard-5", active: true },
        ]}
        title={"Dashboard 5"}
      />


      <Row>
        <Col xl={4} md={6}>
          <SalesChart />
        </Col>
        <Col xl={4} md={6}>
          <StatisticsChart />
        </Col>
        <Col xl={4} md={12}>
          <IncomeChart />
        </Col>
      </Row>

      <Statistics />
    </>
  );
};

export default Dashboard5;
