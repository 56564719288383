import React, { useState, useEffect } from "react";

interface Vendor {
  vendor_id: number;
  fullname: string;
  location: string;
  distance_km: number;
}

interface Order {
  id: number;
  productName: string;
  orderType: string;
  totalQuantity: number;
  inkColors: Record<string, string[]>;
  zipCode: string;
}

interface Assignment {
  order_id: number;
  vendor_id: number;
  status: string;
  assigned_at: string;
  created_at: string;
  updated_at: string;
  vendor: Vendor;
  all_eligible_vendors: Vendor[]; // Store eligible vendors
}

interface AssignedVendorResponse {
  message: string;
  assigned_vendor: Vendor;
  all_eligible_vendors: Vendor[];
  status: string;
}

const statusColors: Record<string, string> = {
  pending: "blue",
  rejected: "red",
  not_responded: "yellow",
  accepted: "green",
};

const VendorList: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [assignments, setAssignments] = useState<Record<number, Assignment | null>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrders();
    fetchAssignments();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/place-orders");
      const data = await response.json();
      setOrders(
        data.map((order: any) => ({
          id: order.id,
          productName: order.cartData[0].productName,
          orderType: order.cartData[0].orderType,
          totalQuantity: order.cartData[0].totalQuantity,
          inkColors: order.cartData[0].inkColors,
          zipCode: order.zip,
        }))
      );
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchAssignments = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/get-assignments");
      const data = await response.json();
      const assignmentMap: Record<number, Assignment> = {};
      data.data.forEach((assignment: Assignment) => {
        assignmentMap[assignment.order_id] = assignment;
      });
      setAssignments(assignmentMap);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  const assignOrderToVendor = async (orderId: number) => {
    setLoading(true);
    try {
      const response = await fetch("https://backend.inkox.com/api/assign-vendor-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ order_id: orderId }),
      });

      const data: AssignedVendorResponse = await response.json();

      if (data.assigned_vendor) {
        // Update state immediately without waiting for fetchAssignments()
        setAssignments(prev => ({
          ...prev,
          [orderId]: {
            order_id: orderId,
            vendor_id: data.assigned_vendor.vendor_id,
            status: data.status,
            assigned_at: new Date().toISOString(),
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            vendor: data.assigned_vendor,
            all_eligible_vendors: data.all_eligible_vendors || [], // Store eligible vendors
          },
        }));

        // Fetch updated assignments after a delay to ensure backend update
        // setTimeout(fetchAssignments, 1000);
      } else {
        alert("No eligible vendors found!");
      }
    } catch (error) {
      console.error("Error assigning order:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2>Order Vendor Assignment</h2>
      <table className="border-inf">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Product Name</th>
            {/* <th>Eligible Vendors</th> */}
            <th>Assigned Vendor</th>
            <th>Status</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {orders.map(order => {
            const assignment = assignments[order.id];

            return (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.productName}</td>
                {/* <td>
                  <ul>
                    {assignment?.all_eligible_vendors?.length ? (
                      assignment.all_eligible_vendors.map(vendor => (
                        <li
                          key={vendor.vendor_id}
                          style={{
                            backgroundColor:
                              vendor.vendor_id === assignment?.vendor?.vendor_id ? "lightgreen" : "transparent",
                            padding: "5px",
                            borderRadius: "5px",
                            margin: "3px 0",
                          }}
                        >
                          {vendor.fullname} (Distance: {vendor.distance_km} km)
                        </li>
                      ))
                    ) : (
                      <span>No Eligible Vendors</span>
                    )}
                  </ul>
                </td> */}
                <td>
                  {assignment ? (
                    <span
                      style={{
                        backgroundColor: "lightgreen",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {assignment.vendor.fullname} (Distance: {assignment.vendor.distance_km} km)
                    </span>
                  ) : (
                    "Not Assigned"
                  )}
                </td>
                <td>
                  <span
                    style={{
                      backgroundColor: statusColors[assignment?.status || "pending"],
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    {assignment?.status || "Not Assigned"}
                  </span>
                </td>
                {/* <td>
                  <button
                    onClick={() => assignOrderToVendor(order.id)}
                    disabled={loading}
                    style={{ cursor: loading ? "not-allowed" : "pointer" }}
                  >
                    {loading ? "Assigning..." : "Assign Order to Vendor"}
                  </button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VendorList;
