import React, { useState, useEffect, ChangeEvent } from "react";
import { Row, Col, Card, Button, Form, Pagination } from "react-bootstrap";
import Table from "../../../components/Table";
import PageTitle from "../../../components/PageTitle";

interface Order {
  id: number;
  name: string;
  productCanvasId: number | null;
  productImages2?: any;
}

interface TableRow {
  id: number;
  name: string;
  parts: string; // Adjust if parts is an object instead of a string
}

const CanvasAssignDetail = () => {
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]); // Filtered dataset
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null); // Editable row ID
  const [selectedAssignId, setSelectedAssignId] = useState<number | null>(null);
  const [filterAssignId, setFilterAssignId] = useState<number | null>(null); // For filtering Assign Id
  const [perPage, setPerPage] = useState(10);

  const fetchOrders = async (page: number, perPage: number) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://backend.inkox.com/api/product?page=${page}&per_page=${perPage}&assignproduct=assign`
      );
      if (!response.ok) throw new Error("Failed to fetch orders");
      const data = await response.json();
      setOrderList(data.data); // Ensure `data.data` is correct based on API response
      setFilteredOrders(data.data); // Set filteredOrders to the new page data
      setTotalPages(data.last_page); // Update total pages
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTableData = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/tool-apparels");
      if (!response.ok) throw new Error("Failed to fetch tool-apparel data");
      const data: TableRow[] = await response.json();
      setTableData(data);
    } catch (error) {
      console.error("Error fetching tool-apparel data:", error);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage, perPage);
    fetchTableData();
  }, [currentPage, perPage]);

  // Update `filteredOrders` when `orderList` changes
  useEffect(() => {
    setFilteredOrders(orderList);
  }, [orderList]);

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = Number(event.target.value);
    setPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value);
    setFilterAssignId(selectedId);

    if (selectedId) {
      const filtered = orderList.filter(
        (order) => order.productCanvasId === selectedId
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orderList); // Reset to show all orders
    }
  };

  const handleEditClick = (rowId: number, assignId: number | null) => {
    setEditRowId(rowId);
    setSelectedAssignId(assignId);
  };

  const handleSaveClick = async (rowId: number) => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/product_update/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productCanvasId: selectedAssignId, // The updated assignId
        }),
      });

      if (!response.ok) throw new Error("Failed to update the productCanvasId");

      const updatedList = orderList.map((item) =>
        item.id === rowId ? { ...item, productCanvasId: selectedAssignId } : item
      );
      setOrderList(updatedList);

      if (filterAssignId) {
        const filtered = updatedList.filter(
          (order) => order.productCanvasId === filterAssignId
        );
        setFilteredOrders(filtered);
      } else {
        setFilteredOrders(updatedList); // Reset to all orders
      }

      setEditRowId(null); // Exit edit mode
      alert("Product updated successfully!");
    } catch (error) {
      console.error("Error updating productCanvasId:", error);
      alert("An error occurred while updating the product.");
    }
  };

  const handleAssignIdChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedAssignId(Number(event.target.value));
  };

  const columns = [
    {
      Header: "Product Id",
      accessor: "id",
      Cell: ({ row }: { row: any }) => <strong>{row.original.id || "N/A"}</strong>,
    },
    {
      Header: "Product Name",
      accessor: "name",
      Cell: ({ row }: { row: any }) =>
        row.original.name || "Product name not found",
    },
    {
      Header: "Product Image",
      accessor: "productImage",
      Cell: ({ row }: { row: any }) => {
        const productImages2 = row.original.productImages2;
        const firstColorKey = productImages2 ? Object.keys(productImages2)[0] : null;
        const frontImageUrl =
          firstColorKey && productImages2[firstColorKey]?.front
            ? `https://backend.inkox.com/${productImages2[firstColorKey].front}`
            : null;

        return (
          <>
            {frontImageUrl ? (
              <img
                src={frontImageUrl}
                alt="Front Product"
                style={{ width: "80px", height: "80px", marginRight: "5px" }}
              />
            ) : (
              <span>No Image Available</span>
            )}
          </>
        );
      },
    },
    {
      Header: "Assign Id",
      accessor: "assignid",
      Cell: ({ row }: { row: any }) => {
        const id = row.original.productCanvasId;
        const matchedRow = tableData.find((item: TableRow) => item.id === id);
        const name = matchedRow ? matchedRow.name : "Name not found";

        return editRowId === row.original.id ? (
          <Form.Select
            value={selectedAssignId || ""}
            onChange={handleAssignIdChange}
          >
            <option value="">Select Assign Id</option>
            {tableData.map((item) => (
              <option key={item.id} value={item.id}>
                {item.id} - {item.name}
              </option>
            ))}
          </Form.Select>
        ) : (
          `${id || "N/A"} - ${name}`
        );
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ row }: { row: any }) => {
        const isEditing = editRowId === row.original.id;

        return isEditing ? (
          <Button
            className="action-icon"
            onClick={() => handleSaveClick(row.original.id)}
          >
            <i className="mdi mdi-content-save"></i>
          </Button>
        ) : (
          <Button
            className="action-icon"
            onClick={() =>
              handleEditClick(row.original.id, row.original.productCanvasId)
            }
          >
            <i className="mdi mdi-pencil"></i>
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Orders", path: "/apps/ecommerce/orders", active: true },
        ]}
        title={"Canvas Assign Detail"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center mb-3">
                <Col md={3}>
                  <h4 className="header-title">Canvas Assign Detail</h4>
                </Col>
                <Col md={6}>
                  <Form.Select
                    onChange={handleFilterChange}
                    value={filterAssignId || ""}
                  >
                    <option value="">Filter by Assign Id</option>
                    {tableData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.id} - {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>

                <Col md={3}>
                  <Form.Label>Records per page</Form.Label>
                  <Form.Select
                    value={perPage}
                    onChange={handleRecordsPerPageChange}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </Form.Select>
                </Col>
              </Row>

              {loading ? (
                <p>Loading...</p>
              ) : (
                <Table
                  columns={columns}
                  data={filteredOrders} // Use the entire filteredOrders without slicing
                  pageSize={perPage}
                  isSortable={true}
                  pagination={false} // Disable internal pagination as we handle it externally
                />
              )}

              <Pagination className="justify-content-center mt-4">
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </Pagination.Item>
                  )
                )}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CanvasAssignDetail;
