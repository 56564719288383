import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useSearchParams } from "react-router-dom";

interface Category {
    id: number;
    value: string;
    label: string;
  }

interface Blog {
    id: number;
    title: string;
    description: string;
    image: string;
    author_id: number;
    date: string;
    add_description: string;
    catg_id: number;
  }

const BlogCategoriesEdit = () => {
  const [image, setImage] = useState<string  | null>(null);
    const [searchParams] = useSearchParams(); // Get query params
    const blogId = searchParams.get("id"); // Extract product ID from URL
  const [blogs, setBlogs] = useState<Blog | null>(null);
  const schemaResolver = yupResolver(
    yup.object().shape({
      catg_name: yup.string().nullable(),
      catg_img: yup.string().nullable(),
    })
  );
  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (blogId) {
        fetchBlogDetails(blogId);
    }
  }, [blogId]);

  const fetchBlogDetails = async (id: string | number) => {
    try {
        const response = await fetch(`https://backend.inkox.com/api/blogs-catg/${id}`);
        const data = await response.json();
        console.log("Fetched Blog Categories Data:", data);

        setValue("catg_name", data.catg_name);


        // Store the blog data
        setBlogs(data);
    } catch (error) {
        console.error("Error fetching blog details:", error);
    }
};

  

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            if (reader.result && typeof reader.result === "string") {
                setImage(reader.result); // Store Base64 string in state
            }
        };

        reader.readAsDataURL(file); // Convert file to Base64
    }
};


  const formatDate = (date: any) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0]; // Ensure YYYY-MM-DD format
};


useEffect(() => {
    if (blogId) {
        fetchBlogDetails(blogId);
    }
}, [blogId]);




const onSubmit = async (data: any) => {
    var authorId = sessionStorage.getItem("authorId") || "";
    const formattedDate = formatDate(data.date);

    if (!authorId) {
      authorId = "2";
  }

    const formData = new URLSearchParams();
    formData.append("catg_name", data.catg_name);
    
    if (image) {
      formData.append("catg_img", image);
    }

    formData.append("author_id", authorId);
    try {
        const response = await fetch(`https://backend.inkox.com/api/blogs-catg/${blogId}`, {
            method: "PUT",
            body: formData,
            headers: {
              "Accept": "application/json",
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error response from server:", errorData);
            alert(`Failed to update blog: ${errorData.message || "Unknown error"}`);
            return;
        }

        const responseData = await response.json();
        console.log("Blog updated:", responseData);
        alert("Blog updated successfully!");

    } catch (error) {
        console.error("Error updating blog:", error);
        alert(`Failed to update blog. Error: ${error}`);
    }
};



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/blogs" },
          {
            label: "Edit Blog Categories",
            path: "/apps/ecommerce/blogs",
            active: true,
          },
        ]}
        title={"Edit Blog Categories"}
      />

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>
                <FormInput
                  name="catg_name"
                  label="Category Name"
                  placeholder="Enter Category Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="catg_name"
                  errors={errors}
                  control={control}
                />


                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">Blog Category Image</h5>
                <Form.Group>
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button type="submit" className="btn w-sm btn-success waves-effect waves-light me-1">
                Update
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default BlogCategoriesEdit;
